.scrollbar-inner > {
	.scroll-element {
		.scroll-bar {
			background-color: $highlight;
			opacity: 1;
		}
		&.scroll-x {
			height: calculateRem(10px);
		}
	}
}
