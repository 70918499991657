ul,ol {
  position: relative;
  display: flow-root;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    position: relative;
    list-style-type: none;

    p:last-child {
      margin-bottom: calculateRem(25px);
    }
  }
}

.mce {
  ul, ol {
    padding: calculateRem(30px) calculateRem(50px) calculateRem(45px) calculateRem(50px);

    @include breakpoint('s') {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include breakpoint('xs') {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    break-inside: avoid;
    border-radius: calculateRem(7px);
    margin-bottom: calculateRem(15px);
    margin-top: 0;

    &.theme1 {
      padding-top: calculateRem(45px);
      margin-top: calculateRem(30px);
      margin-bottom: calculateRem(60px);
    }
  }
  ul {

    li {
      &::before {
        content: '+';
        display: block;
        color: $highlight;
        position: absolute;
        left: 0;
        top: calculateRem(-1px);
        font-weight: bold;
      }

      padding-left: calculateRem(20px);

    }
  }

  ol {
    counter-reset: section;
    li {
      &::before {
        counter-increment: section;
        content: counters(section,".") " ";
        display: block;
        color: $highlight;
        position: absolute;
        left: 0;
        top: 0;
      }

      padding-left: calculateRem(20px);

    }
  }
}


