a.anchor {
  position: relative;
  top: calculateRem(-40px);
  display: block;
  @include breakpoint('s'){
    top: calculateRem(-75px);
  }
}

.hidden {
  display: none !important;
}

.no-padding.wrapper {
  padding: 0 calculateRem(15px);
}

img {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  height: auto;
  border-radius: calculateRem(7px);
}

.highlight {
  color: $highlight;
}

.dark {
  //background-color: $grey;
}

.theme1{
  background-color: $color_grey10 !important;
}

.theme2{
  background-color: $color_grey60 !important;
}

.mce > * {
  &.theme1 {
    border-radius: calculateRem(7px);
  }
}

.text-center {
  text-align: center;

  @include breakpoint('s'){
    //text-align: left;
  }
}

.narrow {
  max-width: calculateRem(660px);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.content-box {
  &::after {
    display: block;
    content: '';
    clear: both;
    float: none;
    width: 100%;
  }
}

hr {
  height: 1px;
  display: block;
  width: 100%;
  left: 0;
  background-color: $color_grey20;
  border: none;
  margin: calculateRem(75px) 0 calculateRem(80px) 0;
  float: none;
  clear: both;

  @include breakpoint('s'){
    margin: calculateRem(50px) 0 calculateRem(50px) 0;
  }
}

iframe {
  width: 100%;
  min-height: calculateRem(500px);
  margin-bottom: calculateRem(30px);
}


@include breakpoint('s','min'){
	.type_2col {
		.content-box {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: calculateRem(40px);

			& > h1,
			& > h2 {
				grid-column: 1/3;
			}
		}
	}
}

/*
@media screen and (min-width: 48rem) and (max-width: 64rem){
	.type_2col {
		.content-box {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: calculateRem(40px);

			& > h1,
			& > h2 {
				grid-column: 1/3;
			}
		}
	}
}

 */



.clearfix {
  clear: both;
  display: block;
  width: 100%;
  height: 1px;
  float: none;
}


/*-------------------
IE Object Fit Fix START
---------------------*/
.custom-object-fit {
  position: relative;
  background-size: cover;
  background-position: center center;
  img {
    opacity: 0;
  }
}
/*--- IE Object Fit Fix END */



