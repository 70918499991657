/*-------------------
Grid START
---------------------*/
@use "sass:math";

@mixin renderGrid($max) {
  @for $i from $max through 1 {
    .col-#{$i} {
      flex: 0 0 (math.div($i, $max) * 100%);
      max-width: (math.div($i, $max) * 100%);
    }
  }
}


.landing .main {
  margin: 0 auto;
  position: relative;
  padding: calculateRem(80px) calculateRem($gutter);
  @include breakpoint('s'){
    padding-top: 0;
    padding-bottom: 1px;
  }
  width: 100%;
  max-width: calculateRem(1090px);
}


.wrapper {
  margin: 0 auto;
  position: relative;
  //padding: calculateRem(80px) calc(#{$gutter/2});
  padding: calculateRem(80px) calculateRem($gutter);
  width: 100%;
  max-width: calculateRem(1560px);

  @include breakpoint('m') {
    padding: calculateRem($gutter);
  }

  @include breakpoint('s') {
    padding: 1rem;
  }

  &.small {
    max-width: calculateRem(1090px);
  }
  &.wide {
    width: 100%;
    max-width: calculateRem(1400px);

    @include breakpoint('xl') {
      width: 100%
    }
  }
  &.full-width {
    width: 100%;
    max-width: none;
    padding: 0;
  }

  .landing .content & {
    &:last-child {
      padding-bottom: calculateRem(150px);

      @include breakpoint('xl'){
        padding-bottom: calculateRem(60px);
      }
      @include breakpoint('s'){
        padding-bottom: calculateRem(10px);
      }
    }
  }
}

section{
  position: relative;
  width: 100%;
  //max-width: calculateRem(1600px);
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.full-width {
  //padding: calculateRem(50px) calculateRem(100px) 0 calculateRem(100px);

  @include breakpoint('s') {
    padding: calculateRem(50px) 0 0 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  //padding: calc(#{$gutter/2}) 0;
  margin: 0 calc(-#{$gutter*0.5});
  //margin: 0 -13px;
  clear: both;

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &:last-child {
    padding-bottom: 0;

    > div {
      //margin-bottom: 0;
    }
  }

  @include breakpoint('xs') {
    //display: block;
  }

  > div, aside {
    box-sizing: border-box;
    padding: 0 $gutter;
    //margin-bottom: $gutter;

  }

  @include renderGrid(12);

  @include breakpoint('xl') {
    .main & .col-4 {
      flex: 0 0 50%;
      max-width: 50%;
      margin: calculateRem(20px) 0;
    }
    .main & .col-3 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .teaser-scroller & .col-3 {
        margin: calculateRem(20px) 0 calculateRem(50px) 0;
    }
  }

  @include breakpoint('l') {
    .landing & .col-4,
    .main & .col-4,
    .main & .col-3 {
      flex: 0 0 50%;
      max-width: 50%;
      margin: calculateRem(20px) 0;
    }
  }

  @include breakpoint('m') {
    .main & .col-4,
    .main & .col-3 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
  }

  @include breakpoint('s') {
    .main & .col-6,
    .main & .col-4,
    .main & .col-3 {
      flex: 0 0 50%;
      max-width: 50%;
      margin: calculateRem(20px) 0;
    }
  }

  @include breakpoint('xs') {
    .landing & .col-4,
    .main & .col-6,
    .main & .col-4,
    .main & .col-3 {
      max-width: none;
      flex: unset;
      width: 100%;
    }
  }

  [class^='col-'], [class*=' col-'] {
    padding: 0 calc(#{$gutter*0.5});
    margin-bottom: $gutter;

    .theme1 & {
      margin-bottom: 0;
    }

    @include breakpoint('s') {
      max-width: none;
      flex: unset;
      width: 100%;
      margin-bottom: $gutter;

      &:last-child {
        //margin-bottom: 0;
        //padding-bottom: 0;
      }
    }
  }
}
/*--- Grid END */
.landing .content {
  padding: 0;
  //margin: 0;
  //max-width: none;
  display: block;
}

.content {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  z-index: 800;
  padding: calculateRem(65px) calculateRem(80px) calculateRem(80px) calculateRem(80px);

  @include breakpoint('l'){
    padding: calculateRem(50px) calculateRem(40px) calculateRem(40px) calculateRem(40px);
  }

  @include breakpoint('s') {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  width: 100%;
  background-color: white;
  max-width: calculateRem(1560px);
  display: flex;
  flex-wrap: wrap;
}

.content-container {
  margin-bottom: calculateRem(80px);
  border-radius: calculateRem(7px);

  .landing & {
    margin: 0;
  }

  &.theme1 {
    padding: calculateRem(40px);
    @include breakpoint('m'){
      border-radius: 0;
      margin-left: calculateRem(-40px);
      margin-right: calculateRem(-40px);
    }
  }

  &.theme-full {
    @include breakpoint('m'){
      padding: 0;
      border-radius: 0;
      margin-left: calculateRem(-40px);
      margin-right: calculateRem(-40px);
    }
  }

  @include breakpoint('l'){
    margin-bottom: calculateRem(40px);
  }

  &.theme1, &.theme-full {
    @include breakpoint('s') {
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    //@include breakpoint('xs') {
    //  margin-left: -0.5rem;
    //  margin-right: -0.5rem;
    //  padding-left: 0.5rem;
    //  padding-right: 0.5rem;
    //}
  }
}




.main {
  //width: calc(100% - calculateRem(390px));
  width: 72%;
  @include breakpoint('m') {
    width: 100%;
  }
}

aside{
  //width: calculateRem(310px);
  //margin-left: calculateRem(80px);
  width: 22.5%;
  margin-left: 5.5%;

  //padding-top: calculateRem(92px);
  @include breakpoint('xl'){
    //margin-left: calculateRem(40px);
    width: 25.5%;
    margin-left: 2.5%;
  }

  @include breakpoint('m'){
    width: 100%;
    margin-left: 0;
    padding-top: calculateRem(40px);
    display: flex;
    flex-wrap: wrap;
  }
}
