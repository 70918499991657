@font-face{
  font-family:"Mark";
  src:url("../fonts/mark/Fonts/MarkW05-Regular.woff2") format("woff2"),url("../fonts/mark/Fonts/MarkW05-Regular.woff") format("woff"),url("../fonts/mark/Fonts/MarkPro.ttf") format("ttf");
  font-weight: normal;
  font-display: swap;
}
@font-face{
  font-family:"Mark";
  src:url("../fonts/mark/Fonts/MarkW07-Bold.woff2") format("woff2"),url("../fonts/mark/Fonts/MarkW07-Bold.woff") format("woff"),url("../fonts/mark/Fonts/MarkPro-Bold.ttf") format("ttf");
  font-weight: bold;
  font-display: swap;
}

@import '../fonts/icomoon/css/gtue_iconfont';


