.search {
	&__open, &__close {
		border: none;
		overflow: hidden;
		width: calculateRem(80px);
		@include breakpoint('m') {
			width: calculateRem(60px);
		}
		height: 100%;
		color: white;
		margin-left: calculateRem(40px);
		align-items: center;
		justify-content: center;
		background-color: transparent;

		&:focus {
			outline: none;
		}
	}

	&__open {
		border-left: 1px solid $color_lightred;
		display: flex;
		@include transition(background-color, .3s);

		&::before {
			@include icon('\e604');
			@include font-size(20, 20);
		}

		@include linkstate {
			background-color: $color_darkred;
		}
	}

	input.search__str {
		all: unset;
		display: none;
		width: calc(100% - 160px);
		color: white;
		font-weight: 600;
		background-color: transparent;
		@include font-size(15, 25);
		padding-left: calculateRem(20px);
		@include placeholder() {
			color: white;
		}
	}

	&__close {
		display: none;
		border-left: 1px solid $color_grey70;

		&::before {
			@include icon('\e60d');
			@include font-size(20, 20);
		}
	}

	&__is_open {
		.search {
			width: 100%;
			position: absolute;
			left: 0;
			height: 100%;
			background-color: $color_grey80;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

		}

		input.search__str {
			display: block;
		}

		.search__open {
			margin: 0;
			background: transparent;
			border: none;
		}

		.search__close {
			margin: 0;
			display: flex;
		}
	}
}

.searchform.content-container {
	padding: calculateRem(40px) calculateRem(175px);
	@include breakpoint('xl'){
		padding: calculateRem(40px);
	}
	h2 {
		text-align: center;
		margin-bottom: calculateRem(30px);
	}
	form.searchform__form {
		.search__str {
			margin-bottom: calculateRem(30px);
		}

		.fieldType_search {
			position: relative;
			&::after {
				position: absolute;
				@include icon('\e604');
				@include font-size(20, 20);
				@include centerY();
				right: calculateRem(10px);
			}
		}

		.searchRow {
			position: relative;
			.fieldType_search {
				position: relative;

				&::after {
					content: none;
				}

				input {
					padding-right: 3.5rem;
				}
			}

			button[type="submit"] {
				all: unset;
				position: absolute;
				width: 3rem;
				height: 100%;
				right: 0;
				top: 0;
				border-radius: 0 0.4375rem 0.4375rem 0;

				&::after {
					position: absolute;
					@include icon('\e604');
					@include font-size(20, 20);
					@include centerY();
					right: calculateRem(10px);
				}

				&:hover, &:focus, &:active {
					background: $highlight;
					color: #FFFFFF;
				}

				span {
					display: none;
				}
			}
		}

		.formgroup {
			column-count: 3;
			column-gap: calculateRem(40px);

			@include breakpoint('xxl'){
				column-count: 2;
			}

			@include breakpoint('xs'){
				column-count: unset;
			}
		}
	}
}

.searchsort {
	padding: calculateRem(12px) calculateRem(40px);
	border-top: 1px solid $color_grey20;
	border-bottom: 1px solid $color_grey20;
	border-radius: 0;

	@include breakpoint('xl'){
		padding-right: 0;
		margin-bottom: calculateRem(40px);
	}

	@include breakpoint('l'){
		padding: calculateRem(10px) 0 calculateRem(30px) 0;
	}

	.content-box {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		@include breakpoint('l'){
			display: block;
		}

		&::after {
			display: none;
		}
	}

	span {
		display: inline-block;
		margin-right: calculateRem(40px);
		@include font-size(15,25);
	}

	&__buttons {
		display: flex;
		@include breakpoint('l'){
			margin-top: calculateRem(10px);
			border-top: 1px solid $color_grey20;
			padding-top: calculateRem(30px);
		}

		@include breakpoint('xs'){
			justify-content: stretch;

			a.btn {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
		}
	}

	.btn {
		padding: calculateRem(10px) calculateRem(40px);
		margin: 0 calculateRem(12px);
		width: auto;
		@include breakpoint('l'){
			&:first-child {
				margin-left: 0;
			}
		}

		@include breakpoint('xs'){
			@include font-size(14,18);
		}

		&::after {
			display: none;
		}
	}
}

.searchresults {
	&__item {
		border-top: 1px solid $color_grey20;
		padding: calculateRem(40px);
		display: flex;
		flex-wrap: nowrap;

		@include breakpoint('s'){
			display: block;
			padding: calculateRem(35px) 0;
		}
	}
	&__image {
		flex: 0 0 calculateRem(270px);
		@include breakpoint('s'){
			margin-bottom: calculateRem(35px);
		}
	}

	&__meta {
		@include font-size(12,22);
		margin-bottom: calculateRem(5px);
		text-transform: uppercase;
	}

	&__content {
		padding-left: calculateRem(80px);

		@include breakpoint('xl'){
			padding-left: calculateRem(40px);
		}

		@include breakpoint('s'){
			padding-left: 0;
		}

		h3 {
			font-weight: normal;
		}

		.btn {
			margin-top: calculateRem(30px);
			@include breakpoint('s'){
				margin-top: calculateRem(15px);
			}
		}
	}
}
