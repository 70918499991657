.datepicker {
	&-top-left, &-top-right {
		border: none;
	}

	&-dropdown {
		border: none;
		border-radius: calculateRem(7px);
		box-shadow: none;
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
		overflow: hidden;
		padding: calculateRem(17px) calculateRem(20px);
	}

	&-panel {
		ul {
			li {
				&.picked {
					color: $highlight;
					font-weight: bold;

					&.highlighted {
						background-color: transparent;
						color: $highlight;
					}

					&:hover {
						color: $highlight;
						background-color: transparent;
					}
				}

				&.highlighted, &:hover, &.highlighted:hover {
					background-color: transparent;
					color: $color-lightred;
				}

				&.current {
					text-align: left;
					font-weight: bold;
					position: relative;
					top: calculateRem(-8px);
					&::after {
						@include font-size(14,20);
						@include icon('\e612');
						position: relative;
						top: calculateRem(2px);
						left: calculateRem(8px);
					}
				}

				&.prev {
					position: relative;
					top: calculateRem(-9px);
					@include font-size(14,20);
					&::before {
						@include icon('\e83e');
						@include centerY();
					}
				}

				&.next {
					position: relative;
					top: calculateRem(-9px);
					@include font-size(14,20);
					&::before {
						@include icon('\e6cc');
						@include centerY();
					}
				}

				&.muted {
					color: $color_grey20;
				}
			}
			&.week {
				border-bottom: 1px solid $color_grey20;
			}
		}
	}
}

