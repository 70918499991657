.cookie-consent {
	background-color: $color_grey10;
	padding: calculateRem(40px) calculateRem(60px) calculateRem(50px) calculateRem(60px);
	@include centerX();
	position: fixed;
	bottom: calculateRem(40px);
	width: calc(100% - 160px);

	@include breakpoint('l'){
		width: calc(100% - 80px);
	}

	@include breakpoint('s'){
		padding: calculateRem(40px);
	}

	max-width: calculateRem(1400px);
	border-radius: calculateRem(7px);
	z-index: 1000;
	.h2 {
		color: $highlight;
		margin-bottom: 0;
		& + p{
			margin-top: calculateRem(20px);
			@include breakpoint('s'){
				margin-top: calculateRem(10px);
			}
		}
	}


	.btn {
		margin-left: calculateRem(40px);

		@include breakpoint('s'){
			margin-bottom: calculateRem(10px);
			display: block;
			width: 100%;
			margin-left: 0;
		}
	}

	&__control {
		margin-top: calculateRem(36px);
		text-align: right;
		display: flex;
		justify-content: flex-end;

		@include breakpoint('xs'){
			display: block;
		}
	}


}
.ch-cookie-consent{
	&__btn-wrapper {
		@include breakpoint('s'){
			margin-left: calculateRem(10px);
		}

		@include breakpoint('xs'){
			margin-left: 0;
		}
	}
	&__category{
		display: none;
	}

	&__read-more {
		text-decoration: underline;
	}
}

.cookie{
	display: none;
}
