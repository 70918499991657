.partnersuche {
	width: calculateRem(350px);
	padding: calculateRem(40px);
	background-color: $color-grey20;
	border-radius: calculateRem(7px);

	@include breakpoint('s') {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.landing & {
		border-radius: calculateRem(7px) calculateRem(7px) 0 0;
		position: absolute;

		bottom: 0;
		right: calculateRem(120px);
		@include breakpoint('xl'){
			right: calculateRem(80px);
		}

		@include breakpoint('m'){
			border-radius: calculateRem(7px);
			position: relative;
			width: auto;
			right: unset;
			margin: $gutter;
			background-color: $color-grey10;
		}

		@include breakpoint('s') {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	z-index: 1;

	html & form input, html & form select {
		border-radius: calculateRem(40px);
		border: 1px solid $color-grey20;
		width: 100%;
		margin-bottom: calculateRem(10px);
		padding: calculateRem(10px) calculateRem(17px);
		@include font-size(15,20);
		color: $color-text;
		@include placeholder(){
			@include font-size(15,20);
			color: $color-text;
		}
	}

	button.btn {
		margin-top: calculateRem(10px);
		width: 100%;
	}
}
