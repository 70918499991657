.video {
	height: 100%;
	video {
		object-fit: cover;
		object-position: center center;
		width: 100%;
		height: auto;
	}

	iframe {
		min-height: 0;
	}

	.embed {
		position: relative;
		&::after{
			content: '▸';
			@include font-size(60,72);
			text-align: center;
			color: white;
			position: absolute;
			@include center();
			display: block;
			width: calculateRem(80px);
			height: calculateRem(80px);
			border: 1px solid white;
			background-color: rgba(50,50,52,0.57);
			border-radius: 50%;
			pointer-events: none;
		}

		&.played::after {
			display: none;
		}
	}

	&.youtube {
		.embed {
			&::after {
				display: none;
			}
		}
	}
}


