header {
	input {
		&#form_query {
			&.search__str {
				background-color: $color-grey80;
				color: white;

				&:focus {
					border-color: white;
				}

				@include placeholder() {
					color: white;
				}

				&:-webkit-autofill {
					-webkit-text-fill-color: white;
				}

			}
		}
	}
}

html {

	%bubble {
		position: absolute;
		right: 0;
		transform: translateX(calc(100% + 12px)) translateY(calc(-50% + 19px));
		top: 50%;
		left: auto;
		color: white;
		@include breakpoint('m') {
			transform: translateY(calc(-100% - 3px));
			top: 0;
		}

		background-color: $highlight;
		border-radius: calculateRem(7px);
		box-shadow: none;
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
		display: block !important;
		@include font-size(12, 22);
		font-weight: normal;
		padding: calculateRem(12px) calculateRem(15px) calculateRem(11px) calculateRem(40px);
		z-index: 1;

		&::before {
			border-style: solid;
			content: "";
			display: block;
			left: calculateRem(-15px);
			border-color: transparent $highlight transparent transparent;
			@include centerY();
			bottom: auto;
			height: calculateRem(10px);
			border-width: calculateRem(9px);

			@include breakpoint('m') {
				border-color: $highlight transparent transparent transparent;
				transform: none;
				left: auto;
				right: calculateRem(10px);
				bottom: calculateRem(-15px);
				top: auto;
			}
		}
	}

	.sfg_box {
		h2 {
			@include font-size(20, 30);
			@include breakpoint('s') {
				@include font-size(17, 22);
			}
			color: $highlight;
		}
	}

	.content .main .sfg_box form.sfg_form, form, form.searchform__form {
		background: transparent;
		width: 100%;
		max-width: none;
		margin: 0;
		padding: 0;

		input:not([type="radio"]):not([type="checkbox"]),
		textarea,
		select {
			width: 100%;
			border-radius: calculateRem(7px);
			border: 1px solid transparent;
			padding: calculateRem(5px) calculateRem(15px);
			height: auto;
			background-color: white;
			color: $color-grey80;
			line-height: calculateRem(28px);
			@include transition(border-color, .3s);

			&:hover {
				outline: none;
				border-color: $color-grey60;
			}

			&:focus {
				border-color: $highlight;
				outline: none;
			}

			@include placeholder() {
				color: $color-brandgrey;
			}
		}

		textarea {
			height: calculateRem(140px);
		}

		input,
		textarea,
		select {
			&:disabled {
				opacity: 0.5!important;
				background: lightgrey!important;
			}
		}


		.fieldType_date {
			label {
				span:last-child {
					display: block;
					position: relative;
				}
			}

			input {
				padding-right: calculateRem(30px);

				&::-webkit-inner-spin-button,
				&::-webkit-calendar-picker-indicator {
					display: none;
					appearance: none;
				}
			}

			p span:last-child {
				display: block;
				position: relative;

				&::after {
					@include icon('\e652');
					position: absolute;
					right: calculateRem(10px);
					@include centerY();
					pointer-events: none;
					color: $color-brandgrey;
				}
			}
		}

		select {
			appearance: none;
			position: relative;
			background: white url(../img/chevron.svg) no-repeat calc(100% - 18px) calculateRem(15px);
		}

		.gridRow {
			margin: 0 calculateRem(-20px);
			width: auto;
			@include breakpoint('l') {
				display: block;
				&.helper {
					display: none;
				}
			}

			.gridCol {
				padding: 0 calculateRem(20px);

				@include breakpoint('l') {
					width: 100%;
				}

				label {
					position: relative;
					margin-bottom: calculateRem(10px);
				}
			}
		}

		label {
			display: block;
			width: 100%;

			input[type="radio"],
			input[type="checkbox"] {
				all: unset;
				display: inline-block;
				width: calculateRem(14px);
				height: calculateRem(14px);
				margin: 0;
				padding: 0;
				vertical-align: text-top;
				font-size: calculateRem(19px);
				line-height: calculateRem(10px);
				text-align: center;
				font-weight: 100;
				background: #ffffff;
				border: 1px solid $color-grey80;

				& ~ span::before {
					content: none;
					display: none;
				}
			}

			input[type="radio"] {
				position: relative;
				border-radius: 100%;

				&:checked::before {
					content: '•';
					position: absolute;
					height: calculateRem(8px);
					width: calculateRem(8px);
					left: calculateRem(3px);
					top: calculateRem(3px);
					line-height: 0;
					color: $highlight;
					background: $highlight;
					border-radius: 100%;
				}
			}

			input[type="checkbox"] {
				border-radius: 2px;

				&:checked::before {
					content: '×';
					color: $highlight;
				}
			}
		}

		.radioreset {
			display: none;
		}

		p.form_error > span.form_error_text,
		p.form_error > label > span.form_error_text {
			@extend %bubble;

			&::after {
				@include icon('\e695');
				color: white;
				@include centerY();
				left: calculateRem(10px);
				font-size: calculateRem(17px);
			}
		}

		.buttons {
			margin-top: calculateRem(50px);
			position: relative;
			display: flex;
			justify-content: space-between;

			@include breakpoint('s') {
				display: block;
			}

			div.next {
				width: auto;
				float: none;
				text-align: left;
				@include breakpoint('s') {
					margin-bottom: calculateRem(20px);
				}
			}

			div.reset {
				width: auto;
				float: none;
				text-align: left;
			}

			&::after {
				display: none;
			}

			.mandatory {
				position: absolute;
				right: 0;
				top: calculateRem(-50px);
				@include font-size(12, 22);
			}
		}
	}

	.content .main .sfg_box form.sfg_form {
		margin-top: calculateRem(20px);

		select {
			background: white url(../img/chevron_light.svg) no-repeat calc(100% - 18px) calculateRem(15px);
			color: $color_grey80;
		}

		button[type="submit"] {
			//margin-top: calculateRem(50px);
			@extend .btn;
			@extend .btn-red;
		}
	}

	.sfg_form {

		.gridCol {
			position: relative;

			&:hover, &:focus-within {
				z-index: 1;
			}
		}

		p div.sfg_hint {
			position: absolute;
			z-index: 1;
			width: 3.5rem;
			height: 1.75rem;
			right: 0;
			top: 1rem;
			overflow: hidden;

			&::before {
				content: 'i';
				position: absolute;
				left: 0;
				top: 0;
				color: $color-brandgrey;
				border: 0.125rem solid $color-brandgrey;
				border-radius: 100%;
				width: 1.75rem;
				height: 1.75rem;
				line-height: 1.5rem;
				text-align: center;
			}

			> span {
				@extend %bubble;
				width: 16rem;
				transform: none;
				left: 3.5rem;
				top: -0.5rem;
				opacity: 0;
				z-index: -1;

				&::after {
					content: 'i';
					color: white;
					@include centerY();
					left: calculateRem(10px);
					font-size: calculateRem(17px);
					border: 0.125rem solid #ffffff;
					border-radius: 100%;
					width: 1.5rem;
					height: 1.5rem;
					line-height: 1.25rem;
					text-align: center;
				}
			}

			&:focus-within, &:hover {
				overflow: visible;

				> span {
					opacity: 1;
					z-index: 1;
				}
			}
		}

		.fieldType_date p .sfg_hint {
			top: 0.375rem;
			width: 4rem;
		}
	}
}

