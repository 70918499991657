.event {
	padding-top: calculateRem(90px);
	h2 {
		margin-bottom: calculateRem(20px);
		margin-top: calculateRem(60px);

		&:first-child {
			margin-top: 0;
		}
	}

	&__item {
		border-top: 1px solid $color_grey20;
		border-bottom: 1px solid $color_grey20;
		padding: calculateRem(40px);
		margin-top: -1px;
		display: flex;
		flex-wrap: nowrap;
		position: relative;

		@include breakpoint('xxl'){
			padding-bottom: calculateRem(100px);
		}

		@include breakpoint('s'){
			display: block;
			padding: calculateRem(35px) 0;
		}
	}
	&__image {
		flex: 0 0 calculateRem(270px);
		margin-bottom: 20px;
		@include breakpoint('s'){
			margin-bottom: calculateRem(35px);
		}
	}

	&__meta {
		@include font-size(15,25);
		margin: 0 0 calculateRem(35px) 0;
		font-weight: bold;
		&::before {
			@include icon('\e652');
			color: $highlight;
			@include font-size(17,25);
			margin-right: calculateRem(15px);
			position: relative;
			top: calculateRem(2px);
		}
	}

	&__content {
		padding: 0 0 0 calculateRem(40px);
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@include breakpoint('s'){
			padding-left: 0;
		}

		h3 {
			margin-bottom: calculateRem(10px);
			width: 100%;
		}

		> div {
			width: 50%;
			@include breakpoint('xxl'){
				width: 100%;
			}
		}

		.btn {
			margin-top: calculateRem(30px);

			@include breakpoint('s'){
				margin-top: calculateRem(15px);
			}
		}
	}

	&__text {

	}

	&__btns {
		padding-left: calculateRem(45px);
		@include breakpoint('xxl'){
			position: absolute;
			left: calculateRem(-5px);
			bottom: calculateRem(45px);
			display: flex;
			flex-wrap: nowrap;
		}

		@include breakpoint('s') {
			position: relative;
			bottom: auto;
			padding-left: 0;
			display: block;
		}

			div {
				&:first-child {
					@include breakpoint('xxl') {
						width: calculateRem(270px);
					}
					@include breakpoint('s') {
						width: 100%;
						margin-right: calculateRem(40px);
					}
				}

				&:last-child {
					@include breakpoint('xxl') {
						padding-left: calculateRem(40px);
					}

					@include breakpoint('s') {
						padding-left: 0;
					}
				}
			}


		.btn {
			width: 100%;
			margin-top: calculateRem(10px);

			@include breakpoint('xxl'){
				width: calculateRem(250px);
				margin-right: 0;
			}

			@include breakpoint('xl'){
				width: 100%;
			}

			@include breakpoint('s') {
				width: calculateRem(260px);
			}
		}
	}
}
