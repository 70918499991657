// Import der Plugin-Styles. Diese *NICHT* verändern.
@import "../plugins/simpleScroller";

// Individueller Code ab hier. Dieser kann verändert werden.
.buehne.scroller {
	& .scroller_controls{
		flex-wrap: wrap;
		padding-top: 0.625rem;
		& .scroller_left{
			flex: 1;
			padding-bottom: 0.625rem;
		}
		& .scroller_right{
			padding-bottom: 0.625rem;
		}
	}
	& .scroller_indicator {
		.scroller_numbers {
			color: white;
			margin-left: 1.5rem;
			padding-bottom: 0.625rem;
		}

		.scroller_counter {
			order: 1;
			margin: 0;
		}

		.scroller_numbers {
			order: 2;
		}
	}
}
.scroller {
	@extend %simpleScroller;
	height: calculateRem(580px);
	overflow: hidden;
	border-radius: calculateRem(7px);

	@include breakpoint('m') {
		height: 50vh;
		background: $highlight;
	}

	&.stage {
		border-radius: 0;
	}

	ul {
		height: 100%;

		li {
			display: block;
			height: 100%;

			position: absolute;
			left: 0;
			z-index: 0;
			& > img,
			& .scroller__content > * {
				opacity: 0;
				transition: opacity 1000ms;
			}

			&.active {
				display: block!important;
				z-index: 1;
				& > img,
				& .scroller__content > * {
					opacity: 1;
				}
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				border-radius: 0;

				@include breakpoint('m') {
					height: calculateRem(260px);
					max-width: none;
					margin: 0 auto;
					position: relative;
					max-height: 42vw;
				}

			}
		}
	}

	.scroller__content {
		background-color: $highlight;
		border-radius: calculateRem(7px) calculateRem(7px) 0 0;
		padding: calculateRem(40px);
		color: white;
		width: calculateRem(350px);
		height: calculateRem(350px);
		position: absolute;
		top: 50%;
		transform: translateY(calc(-50% - 30px));
		left: calculateRem(120px);
		@include breakpoint('xl') {
			left: calculateRem(80px);
		}

		@include breakpoint('m') {
			width: 100%;
			transform: none;
			top: auto;
			position: relative;
			padding-bottom: calculateRem(80px);
			left: 0;
			border-radius: 0;
			text-align: center;
			height: auto;
		}

		@include breakpoint('s') {
			padding-left: 1rem;
			padding-right: 1rem;
		}

	}

	.scroller_controls {
		position: absolute;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		top: 50%;
		transform: translateY(calc(-50% + 175px));
		left: calculateRem(120px);
		@include breakpoint('xl') {
			left: calculateRem(80px);
		}

		background: $highlight;
		z-index: 1;
		width: calculateRem(350px);
		height: calculateRem(75px);
		text-align: right;
		border-radius: 0 0 calculateRem(7px) calculateRem(7px);

		@include breakpoint('m') {
			width: 100%;
			border-radius: 0;
			height: calculateRem(80px);
			bottom: 0;
			top: auto;
			transform: none;
			left: 0;
		}

		.content-container & {
			height: calculateRem(50px);
			width: calculateRem(310px);
			background-color: white;
			border-radius: 0;
			bottom: 0;
			top: auto;
			transform: none;
			left: 0;

			@include breakpoint('xs') {
				width: calculateRem(165px);
			}

		}
	}

	.scroller_right, .scroller_left {
		all: unset;
		color: white;

		width: calculateRem(40px);
		height: calculateRem(40px);
		line-height: calculateRem(40px);
		position: relative;
		display: block;

		.content-container & {
			color: $color-text;
		}

		//@include breakpoint('m') {
		//	display: none;
		//
		//	.content-container & {
		//		display: inline-block;
		//	}
		//}

		&:focus, &:active {
			outline: none;
		}

		&::before {
			all: unset;
			@include font-size(20, 20);
		}
	}

	.scroller_right {
		order: 4;
		margin-right: calculateRem(20px);

		&::before {
			@include icon('\e6cc');
			line-height: inherit;
		}
	}

	.scroller_left {
		order: 3;

		&::before {
			@include icon('\e83e');
			line-height: inherit;
		}
	}

	.scroller_indicator {
		display: contents;

		.scroller_numbers {
			order: 1;
			white-space: nowrap;
			margin-left: 1.25rem;

		}

		.scroller_counter {
			order: 2;
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			top: 0;
			margin: 0 1.25rem;

			.content-container & {
				top: 50%;
				width: 50%;
				left: calculateRem(50px);
			}

			strong {
				display: none;
			}

			a {
				all: unset;
				display: block;
				border: none;
				border-right: 0;
				height: 1px;
				width: 100%;
				background-color: $color-lightred;

				.content-container & {
					background-color: $color_grey20;
				}

				@include breakpoint('m') {
					.stage & {
						width: calculateRem(10px);
						height: calculateRem(10px);
						border-radius: calculateRem(10px);
						background-color: $color-darkred;
						display: inline-block;
						margin: 0 calculateRem(5px);
					}
				}

				&.on {
					background-color: $color-darkred;

					.content-container & {
						background-color: $highlight;
					}

					@include breakpoint('m') {
						.stage & {
							background-color: white;
						}
					}
				}

				@include breakpoint('m') {
					.stage & {
						width: calculateRem(10px);
						height: calculateRem(10px);
					}
				}
			}
		}
	}
}
