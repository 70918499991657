@use "sass:math";

figure {
	img {
		margin-bottom: calculateRem(20px);

		.theme-full & {
			@include breakpoint('m') {
				border-radius: 0;
			}
		}
	}
	margin-bottom: calculateRem(75px);
}

figcaption {
	@include font-size(12,22);
	border-left: calculateRem(5px) solid $highlight;
	padding-left: calculateRem(25px);
	.full & {
		margin-bottom: calculateRem(30px);
	}
	@include breakpoint('m') {
		.full & {
			padding-left: calculateRem(20px);
			margin-left: calculateRem(40px);
			padding-right: calculateRem(40px);
		}
	}

	.theme-full &{
		@include breakpoint('m') {
			margin-left: calculateRem(40px);
			padding-right: calculateRem(40px);
		}
	}
}


figure.imgbox,
figure.video {
	width: 40%;
	margin: 0;

	&.full {
		width: 100%;
		float: none;
		@include breakpoint('m'){
			position: relative;
			left: calculateRem(-40px);
			width: calc( 100% + #{ calculateRem(80px) });

			.group-accordion & {
				position: static;
				width: 100%;
			}

			img {
				border-radius: 0;
			}
		}

		@include breakpoint('s') {
			left: 0;
			width: 100%;

			figcaption {
				margin-left: 0;
			}
		}
	}

	&.big {
		width: 50%;
	}

	&.small {
		width: 23%;
	}

	&.left {
		float: left;
		padding-right: calculateRem(20px);
		margin: 0 calculateRem(20px) 1rem 0;

	}

	&.right {
		float: right;
		padding-left: calculateRem(20px);
		padding-right: 0;
		margin: 0 0 1rem calculateRem(20px);
	}

	&.left, &.right {
		@include breakpoint('m'){
			width: 100%;
			padding: 0;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: calculateRem(50px);
		}
	}


	img {
		display: block;
		width: 100%;
		height: auto;
	}

	@include breakpoint('l'){
		margin-bottom: calculateRem(76px);
	}

	@include breakpoint('s'){
		margin-bottom: calculateRem(50px);
	}




	.embed {
		position: relative;
		display: block;
		//height: 0;
		padding: 0;
		margin-bottom: calculateRem(20px);
		overflow: hidden;

		&.ratio-16-12 {
			padding-bottom: (math.div(100, 16)*12) * 1%;
		}

		&.ratio-16-11 {
			padding-bottom: (math.div(100, 16)*11) * 1%;
		}

		&.ratio-16-10 {
			padding-bottom: (math.div(100, 16)*10) * 1%;
		}

		&.ratio-16-9 {
			padding-bottom: (math.div(100, 16)*9) * 1%;
		}

		&.ratio-16-8 {
			padding-bottom: (math.div(100, 16)*8) * 1%;
		}

		&.ratio-4-3 {
			padding-bottom: (100*0.25*3) * 1%;
		}

		&.ratio-5-4 {
			padding-bottom: (100*0.2*4) * 1%;
		}

		&:not(.noratio) > iframe, > video {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			border: 0;
		}

		&.noratio {
			height: auto;

			& > iframe {
				position: static;
				max-width: 100%;
				border: 0;
			}
		}
	}
}

figure.video, figure.video.left, figure.video.right {
	position: relative;
	width: 66.6666%;
	@include breakpoint('l') {
		width: 100%;
	}

	video {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		border-radius: calculateRem(7px);

		.theme-full &{
			@include breakpoint('m') {
				border-radius: 0;
			}
		}
	}

	.error.noyoutube {
		.hint {
			position: absolute;
			left: 2rem;
			top: 2rem;
			bottom: 2rem;
			right: 2rem;
			padding: 1rem;
			background: rgba(#FFFFFF, 0.75);
			border: 0.125rem solid red;
			border-radius: 1rem;

			label {
				//@extend a;
				//@extend .cta;
				margin: auto;
			}
		}
	}
}
