.pagination {
	border-top: 1px solid $color_grey20;
	padding-top: calculateRem(35px);
	ul {
		display: block;
		text-align: right;
		@include breakpoint('s'){
			text-align: center;
		}
		li {
			display: inline-block;
			a {
				display: block;
				width: calculateRem(40px);
				height: calculateRem(40px);
				border-radius: calculateRem(40px);
				color: $color-text;
				text-align: center;
				font-weight: 600;
				@include font-size(15,40);
			}

			&.active {
				a {
					background-color: $highlight;
					color: white;
					@include font-size(20, 40);
				}
			}
		}
	}
}
