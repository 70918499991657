nav.nav__breadcrumb {
	margin-bottom: calculateRem(65px);
	width: 100%;

	@include breakpoint('m'){
		margin-bottom: calculateRem(50px);
	}
	ul {
		height: auto;
		display: inline-block;
		margin-left: calculateRem(40px);
		@include breakpoint('m'){
			display: none;
		}
		li{
			a, span {
				color: $color-text;
				@include font-size(12,30);
				&::after {
					@include icon('\e60f');
					margin-left: calculateRem(4px);
				}
			}

			&:last-child {
				a, span {
					font-weight: 600;
					color: $highlight;
					&::after {
						display: none;
					}
				}
			}
		}
	}
}
