.teaser {
	background-color: $highlight;
	color: white;
	border-radius: calculateRem(7px);
	text-align: left;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	//align-content: space-between;
	align-content: flex-start;
	position: relative;
	padding-bottom: calculateRem(110px);
	overflow: hidden;

	@include breakpoint('xs') {
		padding-bottom: calculateRem(40px);
	}

	h2, h3, .h3 {
		color: white;
	}

	&:not(.teaser-icon) {
		a:not(.btn) {
			color: white !important;
		}
	}

	a.btn {
		margin: 0;
		bottom: calculateRem(40px);
		//max-width: calc(100% - 80px);
		//white-space: nowrap;
		width: auto;
		min-width: min(calculateRem(230px), calc(100% - calculateRem(80px)));
		max-width: calc(100% - calculateRem(80px));
		@include centerX();

		@include breakpoint('xs') {
			width: calc(100% - calculateRem(80px));
			min-width: 0;
			position: relative;
			margin: 1rem 0 0 calculateRem(40px);
			inset: 0 !important;
			transform: none !important;
		}

		.col-6 &, .col-4 & {
			left: calculateRem(40px);
			transform: none;
		}

		.col-3 & {
			padding-right: calculateRem(20px);
			text-align: center;

			&::after {
				display: none;
			}
		}
	}

	&__content {
		padding: calculateRem(40px) calculateRem(40px) 0 calculateRem(40px);
		width: 100%;

		.h3 + p {
			//margin-top: calculateRem(5px);
		}

		p {
			margin-bottom: 0;
		}
	}

	&-contact {
		background: $highlight url('../../img/Logo.png') no-repeat right calc(100% - 20px);
		background-size: calculateRem(125px) auto;

		@include breakpoint('m') {
			background-image: none;
		}

		@include breakpoint('s') {
			background-image: url('../../img/Logo.png');
		}

		@include breakpoint('xs') {
			background-image: none;
		}

		p {
			@include font-size(20, 30);
		}
	}

	&-sideicon {
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: calculateRem(125px) auto;

		@media (min-width: 768px) and (max-width: 991px) {
			background-image: none !important;
		}
		@media (max-width: 500px) {
			background-image: none !important;
		}

		p:not(.h2) {
			@include font-size(20, 30);
		}
	}

	/*
		&-faq {
			background: $color_grey60 url('../../img/Plakette.png') no-repeat right bottom;
			@include breakpoint('xs'){
				background-image: none;
			}
		}

	 */

	&-image {
		background-repeat: no-repeat;
		background-size: 100% auto;
		overflow: hidden;

		.teaser__image {
			position: relative;
			width: 100%;

			img {
				width: 100%;
				height: auto;
			}

			&::after {
				content: url('../../img/teaser_curve.svg');
				position: absolute;
				display: block;

				left: 0;
				bottom: calculateRem(-8px);
				overflow: hidden;
				width: 100%;
				height: auto;
				/*
				width: calculateRem(672px);
				height: calculateRem(158px);

				.col-4 & {
					width: calculateRem(400px);
					height: calculateRem(94px);
				}
				.col-3 & {
					width: calculateRem(224px);
					height: calculateRem(52px);
				}

				 */
			}
		}

		.col-3 & {
			.teaser__image {
				img {
					height: calculateRem(214px);
					object-fit: cover;
				}
			}
		}

		.col-4 & {
			.teaser__image {
				img {
					height: calculateRem(220px);
					object-fit: cover;
				}
			}
		}

		.col-6 & {
			.teaser__image {
				img {
					height: calculateRem(260px);
					object-fit: cover;
				}
			}
		}

		.teaser__content {
			background-color: $highlight;
			position: relative;
			padding-top: 0;
			border-radius: 0 0 calculateRem(7px) calculateRem(7px);

			a.btn {
				margin: calculateRem(10px) 0 calculateRem(40px) 0;
			}
		}
	}

	&-landscape {
		overflow: hidden;
		padding-bottom: 0;

		.teaser__image {
			height: 100%;
			width: calculateRem(200px);

			@include breakpoint('xxl') {
				width: calculateRem(150px);
			}

			@include breakpoint('xl') {
				height: calculateRem(170px);
				width: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;

				border-radius: calculateRem(7px) 0 0 calculateRem(7px);

				@include breakpoint('xl') {
					border-radius: calculateRem(7px) calculateRem(7px) 0 0;
				}
			}
		}

		.teaser__content {
			width: calc(100% - 200px);
			padding: calculateRem(40px);
			@include breakpoint('xl') {
				width: 100%;
				padding: calculateRem(35px) calculateRem(30px);
			}

			.h3 + p {
				margin-top: calculateRem(10px);
			}

			p {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		@include linkstate {
			color: white;
		}
	}

	&-icon {
		background: transparent;
		border: 1px solid $color_grey20;
		color: $color-text;
		text-align: center;
		overflow: visible;
		padding-bottom: calculateRem(75px);

		i {
			@include centerX();
			@include font-size(30, 80);
			color: $highlight;
			width: calculateRem(80px);
			height: calculateRem(80px);
			border: 1px solid $color_grey20;
			border-radius: calculateRem(40px);
			top: calculateRem(-40px);
			background-color: white;

			&::before {
				@include font-size(30, 80);
			}
		}

		.teaser__content {
			padding: calculateRem(60px) calculateRem(35px) calculateRem(35px) calculateRem(35px);
			@include breakpoint('xl') {
				padding: calculateRem(60px) calculateRem(25px) calculateRem(25px) calculateRem(25px);
			}
		}

		a.btn {
			background-color: $highlight;
			text-align: center;
			padding: calculateRem(10px) calculateRem(20px);

			@include breakpoint('xl') {
				width: calc(100% - 50px);
			}

			@include linkstate {
				color: white;
			}

			&::after {
				display: none;
			}
		}
	}

	&.theme2 {
		.teaser__image {
			&::after {
				content: url('../../img/teaser_curve_grey.svg');
			}
		}

		.teaser__content {
			background-color: $color_grey60;
		}
	}
}

.teaser-icon .teaser__content a {
	color: #DA1F3D !important;
}

/*
.main .teaser-scroller {
	@include breakpoint('m','min'){
		display: flex;
		flex-wrap: wrap;
		margin: 0 calculateRem(-20px);
		padding: calculateRem(50px) 0;
	}
	@include breakpoint('m') {
		margin-bottom: calculateRem(60px);
	}

	> .teaser-scroller {
		@include breakpoint('m') {
			display: flex;
			flex-wrap: nowrap;
			padding: calculateRem(50px) 0;
		}
	}

	.teaser {
		flex: 0 0 calc(25% - 40px);
		max-width: calc(25% - 40px);
		@include breakpoint('xxl'){
			flex: 0 0 calc(33.3333% - 40px);
			max-width: calc(33.3333% - 40px);
		}
		@include breakpoint('xl'){
			flex: 0 0 calc(50% - 40px);
			max-width: calc(50% - 40px);
		}
		margin: 0 calculateRem(20px);
		height: auto;
		margin-bottom: calculateRem(70px);
		@include breakpoint('m'){
			margin-bottom: 0;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}

		@include breakpoint('s'){
			flex: 0 0 70%;
			max-width: 70%;
		}
	}



	@include breakpoint('xl'){
		div.col-3 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	@include scrollbar();
	@include breakpoint('l'){
		-ms-overflow-x: scroll;
		overflow-x: scroll;
		padding-top: calculateRem(20px);
		.row {
			flex-wrap: nowrap;
			display: flex;

			> div.col-3 {
				margin-bottom: calculateRem(40px) !important;
				flex: 0 0 50%;
				max-width: 50%;


			}
		}
	}
	@include breakpoint('xs'){
		.row {
			> div.col-3 {
				flex: 0 0 70%;
				max-width: 70%;
			}
		}
	}
}
*/
.main .teaser-scroller {
	/*
	@include breakpoint('m','min'){
		display: flex;
		flex-wrap: wrap;
		margin: 0 calculateRem(-20px);
		padding: calculateRem(50px) 0;
	}
	@include breakpoint('m') {
		margin-bottom: calculateRem(60px);
	}

	> .teaser-scroller {
		@include breakpoint('m') {
			display: flex;
			flex-wrap: nowrap;
			padding: calculateRem(50px) 0;
		}
	}

	.teaser {
		flex: 0 0 calc(25% - 40px);
		max-width: calc(25% - 40px);
		@include breakpoint('xxl'){
			flex: 0 0 calc(33.3333% - 40px);
			max-width: calc(33.3333% - 40px);
		}
		@include breakpoint('xl'){
			flex: 0 0 calc(50% - 40px);
			max-width: calc(50% - 40px);
		}
		margin: 0 calculateRem(20px);
		height: auto;
		margin-bottom: calculateRem(70px);
		@include breakpoint('m'){
			margin-bottom: 0;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}

		@include breakpoint('s'){
			flex: 0 0 70%;
			max-width: 70%;
		}
	}



	 */

	// Oben abgeschnittene Teaser fixen
	&.scroll-wrapper {
		margin-top: calculateRem(-40px);

		.row {
			padding-top: calculateRem(40px);
		}
	}

	.row {
		margin-left: 0;
		margin-right: 0;
		justify-content: space-between;

		> div.col-3 {
			&:nth-child(odd),
			&:nth-child(even) {
				padding-left: calculateRem(20px);
				padding-right: calculateRem(20px);
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	@include breakpoint('xl') {
		.row {
			> div.col-3 {
				flex: 0 0 50%;
				max-width: 50%;

				&:first-child,
				&:last-child {
					padding-left: calculateRem(20px);
					padding-right: calculateRem(20px);
				}

				&:nth-child(odd) {
					padding-left: 0;
				}

				&:nth-child(even) {
					padding-right: 0;
				}
			}
		}
	}

	@include breakpoint('l') {
		.row {
			flex-wrap: nowrap;
			display: flex;

			> div.col-3 {
				margin-bottom: calculateRem(40px) !important;
				flex: 0 0 50%;
				max-width: 50%;

				&:nth-child(odd),
				&:nth-child(even) {
					padding-left: calculateRem(20px);
					padding-right: calculateRem(20px);
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

			}
		}
	}


	@include breakpoint('xs') {
		.row {
			> div.col-3 {
				flex: 0 0 calculateRem(260px);
				max-width: calculateRem(260px);
			}
		}
	}


}
