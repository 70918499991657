@keyframes rotate {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(22deg);
	}
}


.error {
	position: relative;
	&::before {
		@include icon('\e653');
		@include font-size(600,500);

		color:$color_grey10;
		position: absolute;
		left: calculateRem(-170px);
		top: calculateRem(10px);
		@include breakpoint('s'){
			@include font-size(200,200);
			left: calculateRem(-76px);
			top: calculateRem(-30px);
		}
		margin: 0;
		z-index: 0;
	}
	&__state {
		color: $highlight;
		@include font-size(300,243);

		@include breakpoint('xl'){
			margin-left: calculateRem(-167px);
		}

		@include breakpoint('s'){
			@include font-size(125,100);
			margin-left: calculateRem(-75px);
		}
		font-weight: bold;
		margin-bottom: 0;
		position: relative;
		z-index: 1;


		span {
			animation-duration: .6s;
			animation-delay: 2s;
			animation-name: rotate;
			animation-fill-mode: both;
			transform-origin: bottom left;
			position: absolute;
		}
	}

	h1 {
		@include font-size(110,110);
		@include breakpoint('s'){
			@include font-size(45,45);
		}
		color: $highlight;
		margin-bottom: calculateRem(65px);
		position: relative;

	}
	p {
		margin-bottom: calculateRem(90px);
	}

	&__buttons{
		a {
			margin-right: calculateRem(40px);
			margin-bottom: calculateRem(20px);
		}
	}

	@include breakpoint('xl'){
		.row {
			justify-content: flex-end;

			.col-6:first-child {
				flex: 0 0 100%;
				max-width: 100%;
				text-align: center;
			}
			.col-6:last-child {
				text-align: left;
				flex: 0 0 70%;
				max-width: 70%;
				z-index: 1;
			}
		}
	}

	@include breakpoint('s'){
		.row {
			justify-content: flex-start;
			.col-6 {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.col-6:last-child {
				flex: 0 0 100%;
				max-width: 100%;
				z-index: 1;
			}
		}
	}
}


.error-500.error {

	h1 {
		margin-left: calculateRem(-150px);

		@include breakpoint('s'){
			margin-left: calculateRem(40px);
		}
	}
}
