.download__item {
	text-align: center;
	@include font-size(22,29);
	@include breakpoint('s') {
		text-align: left;
		padding-right: calculateRem(100px) !important;
		@include font-size(18,25);
	}
	font-weight: 500;
	border: 1px solid black;
	margin-top: calculateRem(10px);
	position: relative;
	font-family: "Museo Sans", sans-serif;

	span > label {
		position: absolute;
		right: 2.5rem;
		@include breakpoint('s') {
			right: calculateRem(8px);
		}
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;
		i {
			@include font-size(38, 38);
			margin-left: calculateRem(20px);
			@include breakpoint('s'){
				margin-left: calculateRem(5px);
			}
		}
	}

	span > span {
		position: absolute;
		right: 2.5rem;
		@include breakpoint('s') {
			right: calculateRem(8px);
		}
		display: flex;
		flex-wrap: wrap;
		top: 0;
		height: 100%;
		label {
			display: flex;
			align-items: center;
			position: relative;
			right: 0;
		}
	}
}

#createfiles {
	.accordion {
		h4 {
			@include breakpoint('s') {
				padding-right: calculateRem(90px);
				padding-left: calculateRem(20px);

				&.long {
					padding-right: calculateRem(125px);
				}
			}

			label {
				i{
					&::before {
						left: 0;
					}
				}
			}
		}
		label {
			margin: 0;
			@include font-size(22,29);
			@include breakpoint('s'){
				@include font-size(18,25);
			}
			input {
				margin-left: calculateRem(20px);
				@include breakpoint('s'){
					margin-left: calculateRem(5px);
				}
				width: calculateRem(20px);
				height: calculateRem(20px);
			}
			i {
				@include font-size(38, 38);
				margin-left: calculateRem(20px);
				@include breakpoint('s'){
					margin-left: calculateRem(5px);
				}

				&.icon-arrow_down {
					@include font-size(20, 33);
				}
			}
		}

		h4 {
			span {
				display: flex;
				flex-wrap: wrap;
				top: 0;
				height: 100%;
				label {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.btn {
		float: right;
		margin-top: 0;

		&[disabled]{
			opacity: .5;
			pointer-events: auto! important;
			cursor: not-allowed! important;
		}
	}
}
