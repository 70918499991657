.card {
  width: 100%;
  margin-bottom: calculateRem(40px);
  background-color: $color-grey10;
  border-radius: calculateRem(7px);
  overflow: hidden;

  @include breakpoint('m'){
    width: calc((100% - calculateRem(40px) * 2) / 3);

    &:nth-child(3n + 1),
    &:nth-child(3n + 2){
      margin-right: calculateRem(40px);
    }
  }

  @include breakpoint('s'){
    width: calc((100% - calculateRem(40px)) / 2);

    &:nth-child(even){
      margin-right: 0;
    }
    &:nth-child(odd){
      margin-right: calculateRem(40px);
    }
  }

  @include breakpoint('xs'){
    width: 100%;

    &:nth-child(even),
    &:nth-child(odd){
      margin-right: 0;
    }
  }

  .h3 + p {
    margin-top: calculateRem(5px);
  }

  &__content {
    padding: calculateRem(40px);

    @include breakpoint('s') {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    a.btn {
      display: block;
      margin-bottom: calculateRem(10px);
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }

    + .card__image {
      padding: 0 calculateRem(40px);

      @include breakpoint('s') {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &__image {
    position: relative;
    img {
      border-radius: calculateRem(7px) calculateRem(7px) 0 0;
    }
  }

  &__flag {
    position: absolute;
    left: 0;
    font-weight: 600;
    top: calculateRem(20px);
    background-color: $color_blue;
    color: white;
    padding: 0 calculateRem(10px) 0 calculateRem(20px);
    text-transform: uppercase;
    @include font-size(15,40);
    @include breakpoint('s'){
      @include font-size(14,40);
    }

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: calculateRem(20px) 0 calculateRem(20px) calculateRem(15px);
      border-color: transparent transparent transparent $color_blue;
      right: calculateRem(-15px);
    }
  }

  &-partnersuche {
    @extend .partnersuche;
    background-color: $color-grey10;
  }

  &-news {
    .card__content {
      border-bottom: 1px solid $color_grey20;
      strong {
        display: block;
        margin-bottom: calculateRem(5px);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}





