.logo {
	position: fixed;
	top: calculateRem(100px);
	right: 0;

	&:hover, &:focus, &:active {
		img {
			outline: none;
			filter: drop-shadow(-0.125rem 0 0 #DA1F3D) drop-shadow(0 -0.125rem 0 #DA1F3D) drop-shadow(0 0.125rem 0 #DA1F3D);
		}
	}

	@include breakpoint('xxl','min'){
		right: calc(50% - 780px);
	}

	width: calculateRem(125px);
	.landing & {
		width: calculateRem(200px);
		@include breakpoint('m'){
			top: calculateRem(80px);
			width: calculateRem(100px);
		}
	}
	z-index: 801;

	@include breakpoint('m'){
		top: calculateRem(80px);
		width: calculateRem(100px);
	}
}
