.masonry {
	margin: calculateRem(70px) 0 calculateRem(100px) 0;
	height: calculateRem(1100px);

	@include breakpoint('s'){
		margin: calculateRem(40px) calculateRem(-15px) calculateRem(100px) calculateRem(-15px);
		height: auto;
	}

		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		gap: 20px 20px;
		grid-template-areas:
    "c1 c1 c1 c2 c2 c2 c3 c3 c3 c3 c4 c4"
    "c1 c1 c1 c2 c2 c2 c3 c3 c3 c3 c4 c4"
    "c5 c5 c6 c6 c6 c6 c3 c3 c3 c3 c4 c4"
    "c5 c5 c6 c6 c6 c6 c7 c7 c7 c7 c8 c8"
    "c9 c9 c9 c9 c10 c10 c7 c7 c7 c7 c8 c8"
    "c9 c9 c9 c9 c10 c10 c11 c11 c11 c11 c11 c11"
    "c9 c9 c9 c9 c10 c10 c11 c11 c11 c11 c11 c11"
    "c12 c12 c12 c12 c13 c13 c13 c13 c14 c14 c14 c14"
    "c12 c12 c12 c12 c13 c13 c13 c13 c14 c14 c14 c14";

	@include breakpoint('s'){
		display: block;
	}

	> div {
		overflow: hidden;
		transform: scale(1);
		@include transition(transform, .3s);

		&:hover {
			transform: scale(1.4);
			z-index: 5;
		}

		@include breakpoint('s'){
			margin-bottom: calculateRem(15px);
			transform-origin: center center !important;
		}
	}

	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scale(0);
		opacity: 0;
		@include transition(all, .8s, cubic-bezier(.47,1.64,.41,.8));
	}

	.c1 { grid-area: c1; transform-origin: left center;}
	.c2 { grid-area: c2; }
	.c3 { grid-area: c3; }
	.c4 { grid-area: c4; transform-origin: right center;img{object-fit: contain;object-position: top center} }
	.c5 { grid-area: c5; transform-origin: left center;}
	.c6 { grid-area: c6; }
	.c7 { grid-area: c7}
	.c8 { grid-area: c8; transform-origin: right center; img{object-position: top center}}
	.c9 { grid-area: c9; transform-origin: left center;}
	.c10 { grid-area: c10; }
	.c11 { grid-area: c11;transform-origin: right center;img{object-fit: contain;object-position: top center} }
	.c12 { grid-area: c12; transform-origin: left center;}
	.c13 { grid-area: c13; img{object-fit: contain;object-position: top center}  }
	.c14 { grid-area: c14; transform-origin: right center;}

}
