.sitemap {
	margin-top: calculateRem(45px);

	h2 {
		color: $highlight;
	}

	a {
		color: $color-text;
	}

	> ul {
		/* 1st Level */
		> li {
			border-bottom: 1px solid $color_grey20;
			margin-bottom: calculateRem(40px);
			@include breakpoint('l') {
				margin-bottom: calculateRem(30px);
			}

			&:last-child {
				border-bottom: none;
			}

			> ul {
				@include breakpoint('l') {
					margin-top: 0;
					ul {
						padding-left: calculateRem(20px);
					}
				}
				/* 2nd Level */
				> li {
					margin-bottom: calculateRem(50px);
					display: flex;
					@include breakpoint('l') {
						display: block;
						margin-bottom: calculateRem(35px);
					}

					> a {
						@extend .h3;
						width: 33.333333%;
						@include breakpoint('l') {
							width: 100%;
						}
					}

					> ul {
						padding-top: calculateRem(3px);
						width: 66.666666%;
						@include breakpoint('l') {
							width: 100%;
						}
						/* 3rd Level */
						> li {
							display: flex;
							@include breakpoint('l') {
								display: block;
							}

							> a {
								@extend .h4;
								width: 50%;
								@include breakpoint('l') {
									width: 100%;
								}
							}

							> ul {
								width: 50%;
								@include breakpoint('l') {
									width: 100%;
								}
								/* 4th Level */
								> li {

								}
							}
						}
					}
				}
			}
		}
	}
}
