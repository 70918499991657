@import '../../js/scrollbar/jquery.scrollbar';

table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  //margin-bottom: calculateRem(40px);
  border: 1px solid $color-grey20;
  border-radius: calculateRem(7px);
  overflow: hidden;

  th,
  td {
    padding: calculateRem(18px) calculateRem(20px);
    border-right: 1px solid $color-grey20;
    vertical-align: top;
    @include transition(background-color, .3s);
    @include font-size(15,25);
    @include breakpoint('s'){
      @include font-size(14,24);
    }
  }

  thead th, thead td {
    vertical-align: bottom;
    background-color: $color-grey10;
    font-weight: 600;
    &:last-child {
      border-right: none;
    }
  }

  tbody {
    tr{
      td {
        border-top: 1px solid $color-grey20;

        &:last-child {
          border-right: none;
        }
      }

      &:hover {
        td {
          background-color: rgba(228, 229, 230, 0.2);
        }
      }
    }
  }
/*
  tr:hover {
    background-color: $grey_20;
  }
  */

  tfoot {
    td {
      border-top: 1px solid $color-grey20;
      //background-color: $grey_20;
    }
  }
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
    //@include scrollbar();
    &.scroll-content {
      padding-bottom: calculateRem(15px);
    }
    @include breakpoint('m'){
      padding-bottom: calculateRem(30px);
    }
}

.top-scroller {
  display: none;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .sticky & {
    top: calculateRem(43px);
    position: fixed;

  }
}

/*-------------------
Display Tables START
---------------------*/
div.table {
  display: table;
  .table-row {
    display: table-row;

    .table-cell {
      display: table-cell;
    }
  }
}
/*--- Display Tables END */
