nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li{
      list-style-type: none;
      @include transition(background-color,.3s);
    }
  }
  > ul {

    height: 100%;
    > li{
      display: inline-block;
      height: 100%;
    }
  }
  a {
    @include font-size(15,25);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include transition(background-color,.3s);
  }
}

nav.nav__main {
  .btn {
    display: none;
  }



  width: calculateRem(850px);

  @include breakpoint('xxl'){
    width: 38.875rem;
  }

  @include breakpoint('xl'){
    width: calculateRem(80px);
  }

  ul {
    @include breakpoint('xl'){
      width: calc( (100vw - 180px) / 3 );
    }


    @include breakpoint('m'){
      width: 100vw;
    }
    li {
      &.active, &.open {
        > a {
          color: $color-brandred;
          @include breakpoint('m'){
            font-weight: 600;

            & + .show-children {
              color: $color-brandred;
            }
          }

        }
      }
      &.open {
        > a {
          @include breakpoint('m'){
            & + .show-children {
              &::after {
                transform: rotate(0)!important;
              }
            }
          }
        }
      }

      &.has-children {
        position: relative;

        ul {
          display: none;
          //filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));
          width: 199.5%;
          border-left: 1px solid $color_grey20;
          @include breakpoint('xl'){
            width: calc( (100vw - 180px) / 3 );
            position: absolute;
            top: 0;
            left: 100%;
          }
          @include breakpoint('m'){
            filter: none;
            position: relative;
            width: 100vw;
            left: 0;
          }
          li {

            border-top: 1px solid $color_grey20;
            &:first-child {
              border-top: none;
            }
            @include breakpoint('m', 'min') {
              @include linkstate {
                > .show-children::after {
                  transform: rotate(90deg);
                }
              }
            }
          }
          ul {
            background-color: $color_grey10;
          }
        }
        @include breakpoint('m', 'min'){
          @include linkstate {
            > ul {
              display: block;
            }
          }
        }



        @include breakpoint('m'){
          &.open > ul {
            display: block;
          }
        }
        /*
        @include linkstate{
          > .show-children::after{
            transform: rotate(180deg);
           @include breakpoint('xl'){
             transform: rotate(90deg);
           }
            @include breakpoint('m'){
              transform: rotate(0);
            }
          }

          //@include breakpoint('xl'){
             > ul {
              display: block;
            }
          //}
          @include breakpoint('m'){
            > ul {
              display: none
            }
            &.open > ul {
              display: block;
            }
          }
        }

         */

        > a {
          position: relative;
          + .show-children {
            border: none;
            overflow: hidden;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            @include breakpoint('xxl'){
              right: calculateRem(-8px);
            }
            display: inline-block;
            margin-left: calculateRem(5px);
            width: calculateRem(60px);
            height: calculateRem(60px);

            &::after {
              @include transition(transform, .3s);
              @include icon('\e610');
              transform: rotate(180deg);
              @include font-size(25,42);
            }

            @include linkstate(){
              outline: none;
            }

          }
        }
      }

      @include breakpoint('m','min'){
        &.navi--overflow {
          > ul {
            overflow-x: hidden;
            overflow-y: auto;
            height: calc(100vh - 80px);

            .has-children {
              ul {
                position: relative;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  // 1. Ebene
  > ul {
    display: flex;

    @include breakpoint('xl'){
      filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.25));
      height: auto;
      display: none;
      background-color: white;
      border-right: none;
      //filter: drop-shadow(15px 6px 7px rgba(0, 0, 0, 0.25));

    }
    @include breakpoint('m'){
      filter: none;
    }
    > li {
      width: 25%;
      border-right: 1px solid $color_lightred;
      display: block;
      @include breakpoint('xxl'){
        width: 29%;
        &:nth-child(3){
          width: 21%;
        }
        &:nth-child(4){
          width: 21%;
        }
      }
      @include breakpoint('xl'){
        border-right: 0;
        border-top: 1px solid $color_grey20;
        &:first-child {
          border-top: none;
        }
        width: 100%;
        &:nth-child(3){
          width: 100%;
        }
        &:nth-child(4){
          width: 100%;
        }
        //height: calculateRem(60px);
      }


      @include breakpoint('xl','min') {
        &.open {
          > a {
            color: white;
          }
        }
      }



      &.has-children > a + .show-children {
        height: 100%;
        @include breakpoint('xl'){
          height: calculateRem(60px);
        }

        &::after {
          color: white;
          @include breakpoint('xl'){
            color: $color_grey80;
          }
        }
      }



      @include linkstate{
        > a {
          background-color: $color_darkred;
          @include breakpoint('xl') {
            background-color: $color_grey20;
            color: $color_brandred;
            + .show-children::after {
              color: $color_brandred;
            }
          }
          + .show-children::after {
            transform: rotate(0);
          }
/*
          @include breakpoint('xl', 'min') {
            + .show-children::after {
              transform: rotate(180deg);
            }
          }

 */

          @include breakpoint('m') {
            background-color: $color_darkred;
            color: white;
            + .show-children::after {
              color: white;
            }
          }
        }
      }

      &.open {
        @include breakpoint('m') {
          > a {
            background-color: $color_darkred;
            color: white;
            + .show-children::after {
              color: white;
            }
          }
        }
      }

      a {
        padding: 0 calculateRem(70px) 0 calculateRem(40px);

        @include breakpoint('xxl'){
          padding-left: calculateRem(20px);
        }

        @include breakpoint('m'){
          padding-left: calculateRem(30px);
        }
        font-weight: bold;
        color: $color_grey80;
        @include font-size(15,25);
        justify-content: left;
        height: calculateRem(60px);
      }

      > a {
        color: white;
        height: 100%;

        @include breakpoint('xl'){
          height: calculateRem(60px);
          color: $color_grey80;
          + .show-children::after {
            transform: rotate(90deg);
          }
        }
      }

      // 2. Ebene
      > ul {
        background-color: white;
        @include breakpoint('xl','min'){
          filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.25));
        }
        @include breakpoint('m'){
          background-color: $color_grey10;
        }

        a {
          @include breakpoint('m'){
            font-weight: 400;
          }

          @include linkstate {
            color: $color_brandred;
          }
        }

        > li.has-children {
          position: relative;
          > .show-children::after{
            transform: rotate(90deg);
            @include breakpoint('m'){
              transform: rotate(180deg);
            }
          }

          @include linkstate{
            background-color: $color_grey10;
          }

          a {
            @include linkstate{
              //color: $color_brandred;
              @include breakpoint('m'){
                font-weight: 600;
              }
              + .show-children::after{
                transform: rotate(90deg);
                @include breakpoint('m'){
                  transform: rotate(0);
                }
                color: $color_brandred;
              }
            }
          }

          // 3. Ebene
          > ul {
            //filter: drop-shadow(15px 6px 7px rgba(0, 0, 0, 0.25));
            width: 100%;
            position: absolute;
            bottom: 0;
            // Ab dem 5 Element öffnen sich nach oben
            top: auto;
            left: 100%;
            @include breakpoint('m'){
              filter: none;
              background-color: white;
              position: relative;
              left: 0;
            }
          }

          // die ersten 4 Elemente öffnen sich nach unten
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
            > ul {
              top: 0;
              bottom: auto;
            }
          }
        }
      }
    }
  }


    .nav__sub {
      display: none;
      padding: calculateRem(20px) calculateRem(30px);
      border-top: 1px solid $color_grey20;
      width: 100vw;
      background-color: white;
      flex-wrap: wrap;

      > nav {
        width: 50%;

        ul {
          width: 100%;
          display: block;

          li {
            display: block;
            height: auto;
            filter: none;
          }
        }
      }

      a {
        color: $color-text;
        display: block;
        height: auto;
        @include font-size(14,20);
        padding: calculateRem(10px) 0;
      }

      .nav__lang,.nav__meta,.nav__link {
        display: block;
      }

      .nav__meta {
        margin-left: 0;
      }

      .nav__lang {
        border: none;
      }

      .nav__link {
        width: 100%;
        ul {
          display: flex;
          flex-wrap: nowrap;
          li {
            width: 50%;
            margin: 0;

            &:last-child {
              a {
                left: calculateRem(-24px);
                position: relative;
              }
            }
          }
        }
        a {
          @include font-size(12,25);
          color: $color-brandred;
          padding-left: calculateRem(25px);
        }
      }

  }
}
header {
  #mobilenavi, #hamburger {
    display: none;
  }

  @include breakpoint('xl'){
    #hamburger {
      cursor: pointer;
      overflow: hidden;
      width: calculateRem(80px);
      height: 100%;
      border-right: 1px solid $color_lightred;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font-size(19,19);
      margin:0;

      &::before {
        @include icon('\e606');
      }

      @include linkstate(){
        background-color: $color-darkred;
      }
    }


    #mobilenavi:checked {
      & + label {
        background-color: $color-darkred;
        &::before {
          content: '\e60d';
        }

        & + ul {
          display: block;
        }

      }

    }
  }

  @include breakpoint('m'){
    #hamburger {
      width: calculateRem(60px);
    }
    #mobilenavi:checked + label + ul + .nav__sub {
      display: flex;
    }
  }


}
