.nav__link {
	@include breakpoint('m'){
		display: none;
	}
	ul {
		display: flex;
		li {
			position: relative;


			&:first-child {
				a::before {
					@include icon('\e844');
					margin-right: calculateRem(15px);
				}
				margin-right: calculateRem(20px);
			}
			&:last-child {
				a::before {
					@include icon('\e685');
					margin-right: calculateRem(15px);
				}
			}
			a {
				padding-left: calculateRem(25px);
				&::before {
					@include centerY;
					left: 0;
					@include breakpoint('m'){
						top: calculateRem(16px);
						transform: none;
					}
				}
				@include breakpoint('m','min') {
					white-space: nowrap;
				}
				@include font-size(12,25);
			}
		}
	}
}
