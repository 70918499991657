.card-subnavi {
	background-color: $highlight;
	color: white;

	.h3 {
		color: white;
		margin-bottom: calculateRem(30px);
	}

	li {
		display: block;
		border-bottom: 1px solid $color_lightred;
		&:last-child {
			border-bottom: none;
		}

		a {
			font-weight: bold;
			display: block;
			padding: calculateRem(5px) 0;
		}
	}
}
