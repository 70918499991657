@font-face {
    font-family: 'gtue_iconfont';
    src: url('../font/gtue_iconfont.eot?19797380');
    src: url('../font/gtue_iconfont.eot?19797380#iefix') format('embedded-opentype'),
    url('../font/gtue_iconfont.woff2?19797380') format('woff2'),
    url('../font/gtue_iconfont.woff?19797380') format('woff'),
    url('../font/gtue_iconfont.ttf?19797380') format('truetype'),
    url('../font/gtue_iconfont.svg?19797380#gtue_iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'gtue_iconfont';
    src: url('../font/gtue_iconfont.svg?19797380#gtue_iconfont') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "gtue_iconfont";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-star-empty:before {
    content: '\e601';
}

/* '' */
.icon-search:before {
    content: '\e604';
}

/* '' */
.icon-numbered-list:before {
    content: '\e605';
}

/* '' */
.icon-menu:before {
    content: '\e606';
}

/* '' */
.icon-mail:before {
    content: '\e607';
}

/* '' */
.icon-heart:before {
    content: '\e60a';
}

/* '' */
.icon-check:before {
    content: '\e60c';
}

/* '' */
.icon-x:before {
    content: '\e60d';
}

/* '' */
.icon-shopping-cart:before {
    content: '\e60e';
}

/* '' */
.icon-chevron-right:before {
    content: '\e60f';
}

/* '' */
.icon-chevron-up:before {
    content: '\e610';
}

/* '' */
.icon-chevron-left:before {
    content: '\e611';
}

/* '' */
.icon-chevron-down:before {
    content: '\e612';
}

/* '' */
.icon-user:before {
    content: '\e613';
}

/* '' */
.icon-log-out:before {
    content: '\e614';
}

/* '' */
.icon-filter:before {
    content: '\e616';
}

/* '' */
.icon-pencil:before {
    content: '\e618';
}

/* '' */
.icon-alert-info:before {
    content: '\e61b';
}

/* '' */
.icon-users:before {
    content: '\e627';
}

/* '' */
.icon-vcard:before {
    content: '\e629';
}

/* '' */
.icon-share:before {
    content: '\e630';
}

/* '' */
.icon-home:before {
    content: '\e636';
}

/* '' */
.icon-link:before {
    content: '\e63b';
}

/* '' */
.icon-flag:before {
    content: '\e63c';
}

/* '' */
.icon-book:before {
    content: '\e64a';
}

/* '' */
.icon-newspaper:before {
    content: '\e64b';
}

/* '' */
.icon-calendar:before {
    content: '\e652';
}

/* '' */
.icon-alert-error:before {
    content: '\e653';
}

/* '' */
.icon-lock:before {
    content: '\e685';
}

/* '' */
.icon-minus:before {
    content: '\e68e';
}

/* '' */
.icon-alert-help:before {
    content: '\e694';
}

/* '' */
.icon-alert-triangle:before {
    content: '\e695';
}

/* '' */
.icon-refresh:before {
    content: '\e696';
}

/* '' */
.icon-file:before {
    content: '\e6a6';
}

/* '' */
.icon-trash:before {
    content: '\e6ae';
}

/* '' */
.icon-download:before {
    content: '\e6b0';
}

/* '' */
.icon-bookmark:before {
    content: '\e6b4';
}

/* '' */
.icon-arrow-down:before {
    content: '\e6ca';
}

/* '' */
.icon-arrow-up:before {
    content: '\e6cb';
}

/* '' */
.icon-arrow-right:before {
    content: '\e6cc';
}

/* '' */
.icon-more-horizontal:before {
    content: '\e6de';
}

/* '' */
.icon-comment:before {
    content: '\e800';
}

/* '' */
.icon-add:before {
    content: '\e801';
}

/* '' */
.icon-pictures:before {
    content: '\e802';
}

/* '' */
.icon-corner-up-left:before {
    content: '\e803';
}

/* '' */
.icon-adapter-connected:before {
    content: '\e804';
}

/* '' */
.icon-adapter-disconnected:before {
    content: '\e81b';
}

/* '' */
.icon-layout:before {
    content: '\e81e';
}

/* '' */
.icon-cancel-circle:before {
    content: '\e81f';
}

/* '' */
.icon-template-add:before {
    content: '\e83c';
}

/* '' */
.icon-template-transfer:before {
    content: '\e83d';
}

/* '' */
.icon-arrow-left:before {
    content: '\e83e';
}

/* '' */
.icon-automatic-column:before {
    content: '\e83f';
}

/* '' */
.icon-award:before {
    content: '\e840';
}

/* '' */
.icon-brackets:before {
    content: '\e841';
}

/* '' */
.icon-bug:before {
    content: '\e842';
}

/* '' */
.icon-bugfix:before {
    content: '\e843';
}

/* '' */
.icon-clipboard:before {
    content: '\e844';
}

/* '' */
.icon-clock:before {
    content: '\e845';
}

/* '' */
.icon-data-transfer:before {
    content: '\e846';
}

/* '' */
.icon-duplicate-report:before {
    content: '\e847';
}

/* '' */
.icon-exception-granted:before {
    content: '\e848';
}

/* '' */
.icon-exception–required:before {
    content: '\e849';
}

/* '' */
.icon-exception:before {
    content: '\e84a';
}

/* '' */
.icon-eye:before {
    content: '\e84b';
}

/* '' */
.icon-feature:before {
    content: '\e84c';
}

/* '' */
.icon-grid:before {
    content: '\e84d';
}

/* '' */
.icon-invisible:before {
    content: '\e84e';
}

/* '' */
.icon-notification:before {
    content: '\e84f';
}

/* '' */
.icon-paragraph:before {
    content: '\e850';
}

/* '' */
.icon-plakette:before {
    content: '\e851';
}

/* '' */
.icon-release:before {
    content: '\e852';
}

/* '' */
.icon-remove-brackets:before {
    content: '\e853';
}

/* '' */
.icon-save:before {
    content: '\e854';
}

/* '' */
.icon-select-column:before {
    content: '\e855';
}

/* '' */
.icon-settings:before {
    content: '\e856';
}

/* '' */
.icon-thumbs-down:before {
    content: '\e857';
}

/* '' */
.icon-thumbs-up:before {
    content: '\e858';
}

/* '' */
.icon-tool:before {
    content: '\e859';
}

/* '' */
.icon-unlock:before {
    content: '\e85a';
}

/* '' */
.icon-upload:before {
    content: '\e85b';
}

/* '' */
.icon-na:before {
    content: '\e85c';
}

/* '' */
.icon-assistenz:before {
    content: '\e85d';
}

/* '' */
.icon-ganzjahr:before {
    content: '\e85e';
}

/* '' */
.icon-interior:before {
    content: '\e85f';
}

/* '' */
.icon-komfort:before {
    content: '\e860';
}

/* '' */
.icon-sicherheit:before {
    content: '\e861';
}

/* '' */
.icon-sommerreifen:before {
    content: '\e862';
}

/* '' */
.icon-sonderumbauten:before {
    content: '\e863';
}

/* '' */
.icon-sonderzubehoer:before {
    content: '\e864';
}

/* '' */
.icon-winterreifen:before {
    content: '\e865';
}

/* '' */
.icon-check-large:before {
    content: '\e866';
}

/* '' */
.icon-fax:before {
    content: '\e867';
}

/* '' */
.icon-x-small:before {
    content: '\e868';
}

/* '' */
.icon-alert-error-small:before {
    content: '\e869';
}

/* '' */
.icon-protocol:before {
    content: '\e86a';
}

/* '' */
.icon-paperclip:before {
    content: '\e86b';
}

/* '' */
.icon-check-small:before {
    content: '\e86c';
}

/* '' */
.icon-alert-success:before {
    content: '\e86e';
}

/* '' */
.icon-corner-down-right:before {
    content: '\e86f';
}

/* '' */
.icon-folder:before {
    content: '\e870';
}

/* '' */
.icon-map-pin:before {
    content: '\e871';
}

/* '' */
.icon-microphone:before {
    content: '\e872';
}

/* '' */
.icon-order:before {
    content: '\e873';
}

/* '' */
.icon-revert-date:before {
    content: '\e875';
}

/* '' */
.icon-alert-edit:before {
    content: '\e877';
}

/* '' */
.icon-link-off:before {
    content: '\e878';
}

/* '' */
.icon-select-all:before {
    content: '\e879';
}

/* '' */
.icon-select-off:before {
    content: '\e87b';
}

/* '' */
.icon-zoom-out:before {
    content: '\e87c';
}

/* '' */
.icon-arrow-down-small:before {
    content: '\e87d';
}

/* '' */
.icon-arrow-left-small:before {
    content: '\e87e';
}

/* '' */
.icon-arrow-redo:before {
    content: '\e87f';
}

/* '' */
.icon-arrow-right-small:before {
    content: '\e880';
}

/* '' */
.icon-arrow-undo:before {
    content: '\e881';
}

/* '' */
.icon-arrow-up-small:before {
    content: '\e882';
}

/* '' */
.icon-badge-clock:before {
    content: '\e883';
}

/* '' */
.icon-badge-edit:before {
    content: '\e884';
}

/* '' */
.icon-badge-error:before {
    content: '\e885';
}

/* '' */
.icon-badge-success:before {
    content: '\e886';
}

/* '' */
.icon-badge-x:before {
    content: '\e887';
}

/* '' */
.icon-bus:before {
    content: '\e888';
}

/* '' */
.icon-chevron-down-small:before {
    content: '\e889';
}

/* '' */
.icon-chevron-up-small:before {
    content: '\e88a';
}

/* '' */
.icon-filter-minus:before {
    content: '\e88b';
}

/* '' */
.icon-filter-plus:before {
    content: '\e88c';
}

/* '' */
.icon-grid-9:before {
    content: '\e88d';
}

/* '' */
.icon-gtue-release:before {
    content: '\e88e';
}

/* '' */
.icon-text-module:before {
    content: '\e88f';
}

/* '' */
.icon-trailer:before {
    content: '\e890';
}

/* '' */
.icon-wkz:before {
    content: '\e891';
}

/* '' */
.icon-camera:before {
    content: '\e892';
}

/* '' */
.icon-badge-control:before {
    content: '\e896';
}

/* '' */
.icon-badge-exclamation:before {
    content: '\e897';
}

/* '' */
.icon-social-facebook:before {
    content: '\e898';
}

/* '' */
.icon-social-instagram:before {
    content: '\e899';
}

/* '' */
.icon-social-linkedin:before {
    content: '\e89a';
}

/* '' */
.icon-social-xing:before {
    content: '\e89b';
}

/* '' */
.icon-social-youtube:before {
    content: '\e89c';
}

/* '' */
.icon-storno:before {
    content: '\e89d';
}

/* '' */
.icon-synch:before {
    content: '\e89e';
}

/* '' */
.icon-table:before {
    content: '\e89f';
}

/* '' */
.icon-wifi:before {
    content: '\e8a0';
}

/* '' */
.icon-printer:before {
    content: '\f1a7';
}

/* '' */
.icon-phone:before {
    content: '\f1a8';
}

/* '' */
.icon-video:before {
    content: '\f1a9';
}

/* '' */
.icon-placeholder:before {
    content: '\f1b0';
}

/* '' */
.icon-zoom-in:before {
    content: '\f1b1';
}

/* '' */
.icon-shutdown:before {
    content: '\f1b2';
}

/* '' */
.icon-android:before {
    content: '\f1b3';
}

/* '' */
.icon-apple:before {
    content: '\f1b4';
}

/* '' */
.icon-testing-instrument:before {
    content: '\f1b5';
}

/* '' */
.icon-scanner:before {
    content: '\f1b6';
}

/* '' */
.icon-shield:before {
    content: '\f1b7';
}

/* '' */
.icon-car:before {
    content: '\f1b8';
}

/* '' */
.icon-motorbike:before {
    content: '\f1b9';
}

/* '' */
.icon-van:before {
    content: '\f1ba';
}

/* '' */
.icon-headphones:before {
    content: '\f1bb';
}

/* '' */
.icon-arrow-up-right:before {
    content: '\f1bc';
}

/* '' */
.icon-sach-vip:before {
    content: '\f2a1';
}

/* '' */
.icon-tractor:before {
    content: '\f2a2';
}

/* '' */
.icon-trailer-large:before {
    content: '\f2a3';
}

/* '' */
.icon-twitter:before {
    content: '\f2a4';
}

/* '' */
