.videoLightbox {
	display: none;
	height: 100%;
	position: fixed;
	width: 100%;
	top: 0;
	background-color: rgba(255,255,255,.7);
	z-index: 10000;

	.videoLightbox__open & {
		display: block;
	}

	&__container {
		@include center();
		max-width: calculateRem(1000px);
		position: relative;
		padding: calculateRem(10px);
	}

	&__close {
		@extend .btn;
		//background-color: $superdark !important;
		width: calculateRem(60px);
		height: calculateRem(60px);
		position: absolute !important;
		right: calculateRem(-28px);
		top: calculateRem(-28px);
		border-radius: 50%;

		font-size: calculateRem(33px) !important;

		@include breakpoint('m'){
			right: calculateRem(10px);
			top: calculateRem(-55px);
		}

	}
}
