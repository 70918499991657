.nav__meta {
	margin-left: calculateRem(20px);
	@include breakpoint('m'){
		display: none;
	}
	ul {
		display: flex;
		li {
			height: 100%;
			display: inline-block;
		}
	}
	a {
		height: 100%;
		padding: 0 calculateRem(20px);
	}
}

