body {
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
	font-smooth: always;
	font-family: 'Mark', sans-serif;
	font-smoothing: subpixel-antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	@include font-size(15,25);
	@include breakpoint('s'){
		@include font-size(14,24);
	}
	font-weight: normal;
	color: $color-text;
}


h1, h2, h3, h4, h5, h6, .h3, .h4 {
	color: $color-text;
	font-weight: bold;
}

h1 {
	@include font-size(50,60);
	font-weight: bold;
	@include breakpoint('s'){
		@include font-size(25,30);
	}
	+h4, + p, + figure, + ul, + ol  {
		margin-top: calculateRem(40px);
	}

	+ [class^="content"] {
		margin-top: calculateRem(70px);

		@include breakpoint('m'){
			margin-top: calculateRem(40px);
		}
	}


	.landing & {
		margin-top: -0.8rem;
		margin-bottom: -6.25rem;
		text-align: center;
		@include breakpoint('xl'){
			margin-top: calculateRem(30px);
			margin-bottom: calculateRem(110px);
		}

		@include breakpoint('m'){
			margin-top: 0;
			margin-bottom: calculateRem(60px);
		}
	}
}

.h1 {
	@include font-size(50,60);
	font-weight: bold;
	@include breakpoint('s'){
		@include font-size(25,30);
	}

	.scroller &{
		margin-bottom: calculateRem(15px);

		& + p {
			margin-top: 0;
		}
	}
}

h2, .h2 {
	@include font-size(30,35);
	font-weight: bold;
	@include breakpoint('s'){
		@include font-size(20,30);
	}

	.mce & {
		margin-top: calculateRem(60px);
		@include breakpoint('s') {
			margin-top: calculateRem(30px);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	+h4, + p, + figure, + figure.video, + figure.imgbox {
		margin-top: calculateRem(32px);
		@include breakpoint('s'){
			margin-top: calculateRem(20px);
		}
		.theme1 & {
			margin-top: calculateRem(25px);
		}
	}

	.faq & {
		margin-left: calculateRem(40px);
		margin-bottom: calculateRem(15px);
		@include breakpoint('s'){
			margin-left: 0;
		}
	}

	footer & {
		color: $color-brandred;
		@include breakpoint('m') {
			margin-bottom: calculateRem(30px);
		}

		@include breakpoint('s'){
			@include font-size(25,30);
		}
	}
}

h3, .h3 {
	margin-bottom: 0;
	@include font-size(20,30);
	@include breakpoint('s'){
		@include font-size(17,22);
	}
	 + h1 {
		margin-top: calculateRem(10px);
	}
	 + h4,  + p,  + .multicol,  + figure.video, + figure.imgbox  {
		margin-top: calculateRem(11px);
	}

	.mce & {
		margin-top: calculateRem(40px);
		@include breakpoint('s') {
			margin-top: calculateRem(20px);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.card & {
		color: $highlight;
	}
}

h4, .h4 {
	@include font-size(15, 25);
	@include breakpoint('s') {
		@include font-size(14, 24);
	}

	.mce & {
		margin-top: calculateRem(40px);
		@include breakpoint('s') {
			margin-top: calculateRem(20px);
		}

		+ p {
			margin-top: calculateRem(15px);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	+ p {
		margin-top: calculateRem(25px);
	}
}

p {

}

p + ul {
	//margin: calculateRem(25px) calculateRem(30px);

}

.multicol {
	column-count: 2;
	column-gap: calculateRem(40px);
	> * {
		//column-break-inside: avoid;
		//break-inside: avoid;
	}
	@include breakpoint('m'){
		column-count: unset;
	}

	&.col-3 {
		column-count: 3;
	}

	p {
		&:first-child {
			margin-top: 0;
		}
	}
}

.landing .tinymce {
	margin-top: calculateRem(180px);
	@include breakpoint('xl'){
		margin-top: calculateRem(80px);
	}

	margin-bottom: calculateRem(45px);
	padding: 0 10%;
	@include breakpoint('s'){
		padding: 0;
		margin-top: calculateRem(40px);
		margin-bottom: calculateRem(40px);
		text-align: left;
	}
}

.smalltext {
	font-size: 80%;
}

.underline {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
}

.underline.line-through {
	text-decoration: underline line-through;
}
