a {
	color: $highlight;
	text-decoration: none;
	@include transition(color, .3s);

	&:hover, &:focus, &:active {
		color: inherit;
		text-decoration: none;

		img {
			outline: 0.125rem solid $highlight;
		}
	}

	.mce & {
		text-decoration: underline;
	}

	&.link-external {
		&::after {
			@include icon('\f1bc');
			text-decoration: none;
			top: calculateRem(2px);
			position: relative;
		}
	}

	&[href^="tel:"] {
		border-bottom: 0;
		padding: 0;
	}

	&.block {
		display: block;
		margin-bottom: calculateRem(50px);
	}

	&.inline {
		text-decoration: underline;
	}

	&.dl {
		display: inline-flex;
		font-weight: bold;
		text-decoration: none;

		&::before {
			order: 2;
			content: ' ' attr(data-type) ' (' attr(data-size) ')';
			padding-left: 0.375rem;
			text-transform: uppercase;
			white-space: nowrap;
			font-weight: normal;
		}

		&::after {
			order: 3;
			@include icon('\e6b0');
			@include font-size(17, 25);
			display: inline;
			position: relative;
			white-space: nowrap;
		}
	}
}

#cookie_consent_use_only_functional_cookies {
	border-color: $highlight;
	color: $highlight;

	@include linkstate() {
		background-color: $highlight;
		border-color: $highlight;
		color: white;
	}
}

#cookie_consent_use_all_cookies {
	background-color: transparent;
	border-color: $highlight;
	color: $highlight;

	&:hover, &:active, &:focus {

		background-color: $highlight;
		color: white;
	}
}

.btn {
	display: inline-block;
	position: relative;
	color: white;
	font-weight: 600;
	background-color: transparent;
	@include font-size(15, 18);
	cursor: pointer;
	text-align: left;
	max-width: 100%;
	width: calculateRem(230px);
	@include breakpoint('xl') {
		//width: auto;
	}

	@include breakpoint('m') {
		width: calculateRem(230px);
	}

	border: 1px solid white;
	border-radius: calculateRem(40px);
	padding: calculateRem(10px) calculateRem(40px) calculateRem(10px) calculateRem(20px);
	@include transition(background-color color border-color, .3s);

	.mce & {
		margin-top: calculateRem(20px);
		text-decoration: none;
	}

	&::after {
		@include icon('\e6cc');
	}

	&::after, &.dl::after, &.link-external::after {
		position: absolute;
		font-size: calculateRem(20px);
		right: calculateRem(10px);
		@include centerY();
	}

	@include linkstate() {
		background-color: white;
		color: $highlight;
		outline: none;
	}

	&-invert {
		border-color: $highlight;
		color: $highlight;

		@include linkstate() {
			background-color: $highlight;
			border-color: $highlight;
			color: white;
		}
	}

	&-back {
		padding: 0 calculateRem(15px);
		@include font-size(12, 28);
		border-radius: calculateRem(30px);
		width: auto;

		&::after {
			display: none;
		}

		&::before {
			@include icon('\e83e');
			font-size: calculateRem(16px);
			position: relative;
			top: calculateRem(3px);
			left: calculateRem(-7px);
		}
	}

	&-narrow {
		text-align: center;
		min-width: calculateRem(150px);
		padding-right: calculateRem(20px);

		&::after {
			display: none;
		}
	}

	&-red {
		background-color: $highlight;
		border-color: $highlight;

		&:hover, &:active, &:focus {
			background-color: transparent;
			color: $highlight;
		}
	}
}

footer .scrollTo {
	z-index: 1;
	top: calculateRem(-20px);
	background-color: $highlight;
	width: calculateRem(40px);
	height: calculateRem(40px);
	border: 1px solid $highlight;
	display: block;
	@include centerX;
	border-radius: calculateRem(40px);
	text-align: center;
	color: white;
	@include font-size(20, 40);

	@include breakpoint('s') {
		top: auto;
		right: calculateRem(9px);
		bottom: calculateRem(40px);
		left: auto;
	}

	&::after {
		@include icon('\e6cb');
	}

	@include linkstate() {
		background-color: white;
		color: $highlight;
	}
}

