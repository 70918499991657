/*-------------------
header START
---------------------*/
header {
  background-color: $color_brandred;
  padding: 0;
  height: calculateRem(80px);
  @include breakpoint('m'){
    height: calculateRem(60px);
  }
  margin:0;
  top: 0;
  @include centerX();
  position: fixed;
  width: 100%;
  max-width: calculateRem(1560px);
  z-index: 1000;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: white;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

  > div {
    display: flex;
    flex-wrap: nowrap;
  }

  a {
    color: white;
  }
}

.no-cms header {
  > * {
    display: none;
  }
}
/*--- header END */
