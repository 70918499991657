/*-------------------
Functions START
---------------------*/
@use "sass:math";

@mixin font-size($sizeValue,$lineheight) {
  font-size: ($sizeValue) + px;
  font-size: math.div($sizeValue, 16) + rem;
  line-height: ($lineheight) + px;
  line-height: math.div($lineheight, 16) + rem;
}

@mixin letter-spacing($sizeValue) {
  letter-spacing: ($sizeValue) + px;
  letter-spacing: math.div($sizeValue, 16) + rem;
}

@mixin transition($properties,$time,$ease:ease-out){
  $transition: ();
  @each $property in $properties {
    $transition: append(
            $transition, ($property $time $ease), $separator:comma
    );
  }
  -o-transition: $transition;
  transition: $transition;
}

@mixin center(){
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
}

@mixin centerX(){
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

@mixin centerY(){
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}



@mixin scrollbar(){
  scrollbar-color: $highlight $color-grey10;

  &::-webkit-scrollbar{
    width: calculateRem(10px);
  }

  &::-webkit-scrollbar-track {
    background-color: $color-grey10;
    border-radius: calculateRem(10px);
  }
  &::-webkit-scrollbar-thumb{
    background-color: $highlight;
    border-radius: calculateRem(10px);
  }
}

@mixin icon($icon){
  font-family: "gtue_iconfont" !important;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $icon;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}


@mixin keyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation ($delay, $duration, $animation, $direction, $fillmode: forwards,$count:1) {
//

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-timing-function: $direction;
  animation-iteration-count: $count;
}


@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  //$remSize: (($size) / 16px);
  @return #{$remSize}rem;
}

@mixin linkstate {
  &:focus, &:active, &:hover {@content}
}


