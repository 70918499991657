.nav__lang {
	overflow: hidden;
	width: calculateRem(80px);
	height: 100%;
	border-right: 1px solid $color_lightred;
	@include breakpoint('m'){
		display: none;
	}
	@include linkstate(){
		overflow: visible;
	}
	ul {
		li {
			width: 100%;
			a {
				@include breakpoint('m'){
					span {
						display: none;
					}
					&::after {
						content: attr(data-label);
						display: inline-block;
					}
				}

				@include linkstate(){
					background-color: $color_darkred;
					@include breakpoint('m'){
						background-color: transparent;
					}
				}
			}

			& ~ li:last-child {
				background-color: white;
				filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.25));

				a {
					color: $color-text;
					@include linkstate(){
						background-color: $color_grey20;
						@include breakpoint('m'){
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}
