/*-------------------
Farben START
---------------------*/

$color_brandred:    #DA1F3D;
$color_darkred:     #A6182F;
$color_lightred:    #E14C64;

$color_brandgrey:   #C2C4C6;
$color_grey92:      #323234;
$color_grey80:      #555555;
$color_grey70:      #777777;
$color_grey60:      #888888;
$color_grey20:      #DADBDD;
$color_grey15:      #E5E5E5;
$color_grey10:      #F2F2F2;

$color_blue:        #037DB2;


$color-text:    $color_grey80;
$highlight:     $color_brandred;
/*--- Farben END */
