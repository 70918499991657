.obfcm {
  .gridRow {
    margin-bottom: 1rem !important;
  }

  .info-inner.invalid {
    border: 1px solid red;
    padding: 1rem;
  }

  .sfg_box.full-form-width {
    width: 100%;
  }

  .message-box {
    margin-bottom: 1rem;
  }
}