@use "sass:math";

.interactivemap_wrapper {
	position: relative;
	width: 100%;
	height: 0;
	// Aspect Ratio, z.B. 16/9
	padding-top: #{math.div(100%, math.div(16, 9))};

	// das plugin stiehlt die Klasse, daher CSS über > div
	> div {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: calculateRem(7px);
	}
}

.map__form {
	background-color: #E5E5E5;
	padding: 1rem;

	.awesomplete {
		width: 100%;
	}

	label {
		color: #DA1F3D;
		font-weight: bold;
	}

	.align-right {
		display: flex;
		justify-content: flex-end;
	}

	button {
		margin-top: 0.5rem;
	}
}

.address_wrapper {
	display: flex;
	flex-direction: row-reverse;
	column-gap: 2.5rem;

	> * {
		width: 100%;
	}

	@include breakpoint('s'){
		flex-direction: column-reverse;
	}
}