footer {
  padding: calculateRem(80px) 0;
  background-color: $color-grey10;
  //margin-top: calculateRem(110px);
  top: 0;

  .no-cms & {
    display: none;
  }
  @include centerX();
  position: relative;
  z-index: 900;
  width: 100%;
  max-width: calculateRem(1560px);

  @include breakpoint('s'){
    padding: calculateRem(35px) 0;
  }

  @include breakpoint('m'){
    //margin-top: calculateRem(60px);
  }

  @include breakpoint('s'){
    //margin-top: calculateRem(10px);
  }

  .wrapper {
    padding: 0 calculateRem(80px);

    @include breakpoint('s') {
      padding: 0 calculateRem(92px) 0 1rem;

      .row [class^="col-"], .row [class*=" col-"] {
        padding-right: 0;
      }
    }

    .row {
      padding-top: 0;


      @include breakpoint('m'){
        .col-4 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        div:nth-child(3){
          margin-left: 0
        }
      }

      > div {
        margin-bottom: 0;
        &:nth-child(1) {
          flex: 0 0 20%;
          max-width: 20%;
          @include breakpoint('xl') {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
        &:nth-child(2){
          flex: 0 0 40%;
          max-width: 40%;
          @include breakpoint('xl'){
            flex: 0 0 60%;
            max-width: 60%;
          }

          column-count: 2;
          column-gap: 40px;

          @include breakpoint('s'){
            column-count: unset;
          }

          @include breakpoint('xl'){
            margin-bottom: calculateRem(40px);
          }

          a {
            color: $color-text;
            @include linkstate(){
              color: $color_brandred;
            }

            span {
              display: inline-block;
              padding-right: calculateRem(5px);
              width: calculateRem(50px);
              @include font-size(12,25);
            }
          }
        }
        &:nth-child(3) {
          flex: 0 0 30%;
          max-width: 30%;
          @include breakpoint('xl'){
            margin-left: 30%;
            flex: 0 0 60%;
            max-width: 60%;
          }
        }

        &:last-child {
          flex: 0 0 10%;
          max-width: 10%;
          @include breakpoint('xl') {
            flex: 0 0 8.3333333333%;
            max-width: 8.3333333333%;
          }
          a {
            color: $color-text;
            @include linkstate(){
              color: $color_brandred;
            }
          }
        }

        > div:nth-last-child(1),> div:nth-last-child(2){
          margin-bottom: 0;
        }

        @include breakpoint('s') {
          &[class^="col-"] {
            margin-left: 0;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }



      p{
        margin-bottom: 0;
        break-inside: avoid;

        &:first-child{
          @include breakpoint('s'){
            margin-bottom: calculateRem(30px);
          }
        }
      }
    }
  }

}
