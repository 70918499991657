footer {
	nav {
		ul {
			display: block;
			margin: 0;
			padding: 0;
			list-style-type: none;
			column-count: 2;
			column-gap: 40px;
			@include breakpoint('s'){
				column-count: unset;
			}
			li {
				break-inside: avoid;
				display: block;
				a {
					color: $color-text;
					align-items: unset;
					justify-content: unset;
					font-weight: bold;
					line-height: calculateRem(26px);

					@include linkstate(){
						color: $color_brandred;
					}

					@include breakpoint('xs'){
						@include font-size(14,25);
					}

					&::before {
						@include icon('\e6cc');
						position: relative;
						margin-right: calculateRem(6px);
						top: calculateRem(5px);
					}
				}
				@include breakpoint('s') {
					&:nth-last-child(4) {
						margin-top: calculateRem(25px);
					}
				}
			}
		}
	}

	.row .nav__socials {
		a {
			@include font-size(20,20);
			@include breakpoint('m'){
				@include font-size(28,28);
			}
		}

		@include breakpoint('m'){
			width: auto;
			position: absolute;
			right: calculateRem(10px);
			top: calculateRem(135px);
		}
		@include breakpoint('s'){
			top: calculateRem(90px);
		}
	}
}
