ul.image-gallery {
	--gutter: 5;
	--gap: 1rem;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	column-gap: var(--gap);
	row-gap: var(--gap);

	li {
		display: block;
		box-sizing: border-box;
		width: calc((100% - (var(--gutter) - 1) * var(--gap)) / var(--gutter));
	}
}