// simpleScroller v3.3.1 | (c) Alexander Klenner
// Grundlegendes Funktionslayout. Für Updatefähiogkeit *NICHT* verändern,
// sondern in Individualcode überschreiben.
%simpleScroller {
	position: relative;

	> ul {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		z-index: 1;
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		overflow-x: auto;
		scroll-snap-type: x mandatory;

		&.vertical {
			flex-direction: column;
		}

		> li {
			display: block;
			flex-grow: 0;
			flex-shrink: 0;
			position: relative;
			margin: 0;
			padding: 0;
			width: 100%;
			scroll-snap-align: start;

			&::before {
				content: none;
				display: none;
			}

			> div {
				position: relative;
				height: 100%;
			}
		}

	}

	&.js {
		> ul {
			overflow: hidden;
			scroll-snap-type: none;

			> li {
				scroll-snap-align: unset;
			}
		}

	}

	.scroller_left,
	.scroller_right {
		position: absolute;
		z-index: 2;
		box-sizing: border-box;
		top: 0;
		bottom: 0;
		padding: 0;
		border: none;
		background-color: transparent;

		&::-moz-focus-inner {
			border: none;
		}

		&::before {
			display: inline-block;
			vertical-align: middle;
			font-size: 5rem;
			line-height: 0;
		}
	}

	.scroller_left {
		left: 0;
		padding-right: 2rem;


		&::before {
			content: "\00AB"; // "<<"
		}

	}

	.scroller_right {
		right: 0;
		padding-left: 2rem;


		&::before {
			content: "\00BB"; // ">>"
		}
	}

	.scroller_indicator {

		.scroller_numbers {
			white-space: nowrap;
		}

		.scroller_counter {
			text-align: center;

			a {
				display: inline-block;
				box-sizing: border-box;
				margin: 0 0.25em;
				width: 0.75rem;
				height: 0.75rem;
				border: 0.125rem solid #888888;
				background: #ffffff;

				&:hover &:focus, &:active {
					outline: none;
				}

				&.on {
					background: #888888;
				}
			}
		}
	}
}
