.group-register {
	border-top: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;

	margin-top: 3.5rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > input {
		position: absolute;
		opacity: 0;
		z-index: -1;

		& + .toggle {
			display: block;
			float: left;
			order: 1;
			margin-top: -2.125rem;
			margin-left: 0.75rem;
			padding: 0.25rem;
			line-height: 1.5rem;
			border: 1px solid lightgray;
			background: white;

			& ~ .toggle {
				margin-left: -1px;
			}

			label {
				display: block;
			}

			& + .content-container {
				display: none;
				border-top: none;
				margin: 0 0.75rem;
			}
		}

		&:checked + .toggle {
			background: lightgrey;

			& + .content-container {
				display: block;
				order: 2;
				flex-grow: 1;
				width: 100%;
			}
		}

		&:focus-visible + .toggle {
			background: lightblue;
		}

		&:-moz-focusring + .toggle,
		& + .toggle:hover {
			background: lightblue;
		}
	}
}

.group-accordion {
	border: 1px solid $color_grey20;
	border-radius: calculateRem(7px);

	.faq & {
		border: none;
		border-bottom: 1px solid $color_grey20;
		border-radius: 0;
	}

	& > input {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:first-child + .toggle {
			border-top: none;
		}

		& + .toggle {
			margin: 0;


			border-top: 1px solid $color_grey20;

			& ~ .toggle {
				margin-top: -1px;
			}

			label {
				display: block;
				position: relative;
				margin-bottom: 0;

				padding: calculateRem(24px) calculateRem(40px) calculateRem(26px) calculateRem(40px);

				@include breakpoint('s') {
					padding: calculateRem(16px) calculateRem(50px) calculateRem(16px) calculateRem(20px);
					.faq & {
						padding: calculateRem(20px) calculateRem(50px) calculateRem(20px) 0;
					}
				}

				&::after {
					@include icon('\e612');
					position: absolute;
					margin: 0;
					width: calculateRem(40px);
					text-align: center;
					@include font-size(30, 40);
					right: calculateRem(18px);
					top: 50%;
					margin-top: calculateRem(-20px);
					//transform-origin: top;
					@include transition(transform, .3s);
					@include breakpoint('s') {
					}
				}
			}

			& + .content-container {
				//display: none;
				//padding: 0 calculateRem(40px) calculateRem(30px) calculateRem(40px);

				height: 0;
				padding: 0;
				overflow: hidden;

				@include breakpoint('s') {
					//padding: 0 calculateRem(20px) calculateRem(25px) calculateRem(20px);
					.faq & {
						padding: 0 0 calculateRem(10px) 0;
					}
				}

				margin-bottom: 0;
				border-radius: 0;
			}
		}

		&:checked + .toggle {
			border-bottom: none;
			color: $highlight;

			label::after {
				transform: rotate(180deg);

				color: $highlight;
			}
		}

		//& + .toggle + .content-container:focus-within {
		&:checked + .toggle + .content-container {
			display: block;
			height: auto;
			padding: 0 calculateRem(40px) calculateRem(30px) calculateRem(40px);
			@include breakpoint('s') {
				padding: 0 calculateRem(20px) calculateRem(25px) calculateRem(20px);
			}
			border-top: none;
		}
	}
}
