@include keyframe(cd-bounce){
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	60% {
		opacity: 1;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@include keyframe(cd-scaleFade){
	0% {
		opacity: 0;
		transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@include keyframe(cd-scale){
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}



@include keyframe(fadein){
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@include keyframe(cd-bounce-inverse){
	0% {
		opacity: 0;
		transform: translateX(100px);
	}

	60% {
		opacity: 1;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.is-hidden  {
	visibility: hidden;
}

.fade-in {
	opacity: 0;
	@include transition(opacity, .5s);
}


.animation_fromRight {
	transform: translateX(0) !important;
	@include breakpoint('m'){
		transform: rotate(165deg) !important;
	}
}



.animation_bounce-in {
	visibility: visible;
	opacity: 0;
	@include animation(.6s, 1.5s, cd-bounce, ease-in-out);
}

.animation_scaleFade {
	visibility: visible;
	@include animation(.6s, 1.5s, cd-scaleFade, ease-in-out);
}

.animation_scale-in {
	visibility: visible;
	transform: scale(1) !important;
	//@include animation(.5s, .8s, cd-scale, ease-in-out);
}

.animation_fade-in {
	//visibility: visible;
	opacity: 1 !important;
	//@include animation(.5s, .8s, fadein, ease-in-out);
}

.animation_gettogether {
	opacity: 1 !important;
	transform: translate3d(0, 0, 0) !important;
}

.animation_line{
	&::before {
		width: 100%;
	}
	h2 {
		transform: translateY(0);
		color: $color-text;

		.dark & {
			color: $color-text;
		}
	}
}

.animation_bounce {
	opacity: 1 !important;
	transform: scale(1) !important;
}

.animation_letter {
	opacity: 1 !important;
	letter-spacing: 1px !important;
}

.animation_bounce-in2 {
	opacity: 1!important;
	transform: translateX(0px) !important;
}

