@charset "UTF-8";

/*-------------------
Fuer SIX.de
erstellt am: 29.03.21
Marek Boettcher
---------------------*/

@import "general/basics";
@import "general/reboot";
@import "general/breakpoints";
@import "general/mixins";
@import "general/grid";
@import "general/utils";
@import "general/animations";

@import "sections/footer";
@import "sections/intro";
@import "sections/header";

@import "font";
@import "typography";
@import "links";
@import "lists";
@import "logo";
@import "video";

@import "formgen_render";
@import "form";
@import "obfcm";

@import "modules/register";
@import "modules/downloads";
@import "modules/videolightbox";
@import "modules/search";
@import "modules/teaser";
@import "modules/partnersuche";
@import "modules/figures";
@import "modules/tables";
@import "modules/cards";
@import "modules/events";
@import "modules/sitemap";
@import "modules/error";
@import "modules/slider";
@import "modules/imagegallery";
@import "modules/map";

@import "navigation/navigation";
@import "navigation/meta";
@import "navigation/footer";
@import "navigation/lang";
@import "navigation/link";
@import "navigation/breadcrumb";
@import "navigation/pagination";
@import "navigation/subnavi";

@import "plugins/masonry";
@import "plugins/datepicker";
@import "plugins/cookiebot";
@import "plugins/scrollbar";

@import "../js/datepicker/datepicker.min";


//@import "well";
//@import "referenzen";

//@import "lity";
//@import "slider";
//@import "counter";



/*-------------------
General START
---------------------*/
html {
  overflow-y: scroll;
  scroll-padding-top: 6rem;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
  background-color: $color_grey15;
  padding-top: calculateRem(80px);

  @include breakpoint('m'){
    padding-top: calculateRem(60px);
  }

}

h2 + .multicol {
  margin-top: 1.5rem;
}

figure + p {
  margin-top: 0.8rem;
}

.mce h3 + figure.imgbox,
.mce h2 + figure.imgbox {
  margin-top: 2rem;
}


/*--- General END */




